
const DateInput = ({register, error, content, info, validate, errorTypes = []}) => {
    const {name, label, value, required, description} = info;
    console.log(error)
    return <>
        <label className="label">{content[label] ?? label} {!required && "(optional)"}</label>
        <input type="date" className="input" 
            name={name} defaultValue={value} 
            {...register(`${name}`, { required: required, validate: validate })}
            aria-invalid={error ? "true" : "false"}
        />
        {error?.type == 'required' && <span>{content.fieldRequired}</span>}
        {errorTypes.map(type => {
            if(error?.type == type?.name){
                return <span>{type.message}</span>
            }
        })}
        {description && <span className="help">{description}</span>}
    </>
}

export default DateInput;