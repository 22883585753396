import { useState, useEffect } from "react"
import { Column, Columns } from "../../../layout";

import { AddressForm } from "./AddressForm";

export const ContactsAddresses = ({addresses, selectAddress, deleteAddress, addAddress, content, register}) => {
    const [addressEdit, setAddressEdit] = useState(false);
    const [addressOnEdit, setAddressOnEdit] = useState(null);

    function addAddressLocal() {
        setAddressEdit(true)
        setAddressOnEdit({})
    }

    function cancel() {
        setAddressEdit(false);
        setAddressOnEdit(null);
    }

    useEffect(() => {
        if(addresses?.length == 0 || addresses == null){
            addAddressLocal();
        }
    }, [])

    let addressesView = addresses?.map(a => <ContactsAddress address={a} selectAddress={() => selectAddress(a)} deleteAddress={() => deleteAddress(a)} content={content} />)
    return <>
        <h4>{content.address ?? "Adresse"}</h4>
        {addressEdit && addressOnEdit != null 
            ? <AddressForm address={addressOnEdit} content={content} cancel={(addresses != null && addresses.length > 0) ? cancel : null} register={register} index={0}/>
            : <>{addressesView} <button className="button" onClick={addAddressLocal}>{content?.addAddress ?? "Erstelle eine neue Adresse"}</button> </>
        }
    </>
}

const ContactsAddress = ({address, selectAddress, deleteAddress, content}) => {
    let a = address?.address;
    console.log(address.primary)
    return (<>
        <Columns classes="is-mobile">
            <Column classes="is-1">
                <input type="radio" checked={address.primary} aria-checked={address.primary} value={address.id} name="primaryAddress" id={address.id + '-primary'} onClick={selectAddress}/>
            </Column>
            <Column>
                <p>{a.addressLine1}</p>
                {a.addressLine2 && <p>{a.addressLine2}</p>}
                <p>{a.country}-{a.plz} {a.city}</p>
            </Column>
            <Column classes="is-2">
                <button disabled={address.primary} aria-disabled={address.primary} className="button is-pulled-right" title={content?.remove ?? "Entfernen"}>
                    <span class="icon">
                        <i class="fas fa-trash" ></i>
                    </span>
                </button>
            </Column>
        </Columns>
    </>)
}