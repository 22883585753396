const TextInput = ({register, error, content, info}) => {
    const {name, label, value, required, description} = info;
    return <>
        <label className="label">{content[label] ?? label} {!required && "(optional)"}</label>
        <input className="input" name={name} defaultValue={value} {...register(`${name}`,{ required: required })} aria-invalid={error ? "true" : "false"} />
        {error && <span>{content.fieldRequired}</span>}
        {description && <span className="help">{description}</span>}
    </>
}

export default TextInput;