const BadgeType = props => {
    const {type} = props;
    const handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        props.setChange(value);
    }
    return (
        <>
            <h6>Badge-Typ</h6>
            <select name="badgeType" id="badgeType" onChange={handleInputChange} className="input">
                <option selected={type == "SMS"} value="SMS">SMS</option>
                <option selected={type == "email"} value="email">E-Mail</option>
                <option selected={type == "print"} value="print">Druck</option>
            </select>
        </>
    )
}

export default BadgeType;