import React, { useEffect, useContext, useState } from 'react';
import {  Link, useParams, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

import { ApiContext } from './services/ApiContext';

import { readTicketById, readEventData, checkInTicket, updateParticipationById, readParticipationById } from './api/CheckinApi';
import { ErrorFormatter } from './utils/ErrorFormatter';

import { Page, Columns, Column, Box } from './layout';
import Participant from './components/Participant';
import { addYears, format, subYears } from 'date-fns';
import _ from 'lodash';
import BadgeType from './components/BadgeType';
import EditParticipation from './components/EditParticipation';

const CheckIn = (props) => {
    const { service } = useContext(ApiContext);
    const params = useParams();
    const navigate = useNavigate();

    const [infos, setInfos] = useState([]);
    const [ticket, setTicket] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false)
    const [badgeType, setBadgeType] = useState("SMS")
    const [workshops, setWorkshops] = useState(null);
    const [provisions, setProvisions] = useState(null);
    const [hosts, setHosts] = useState(null);
    const [changed, setChanged] = useState(false);
    const [success, setSuccess] = useState(false);

    function getAccommodationSex(salutation){
        let short;
        switch(salutation){
            case "Herr":
                short = "m"
                break;
            case "Frau":
                short = "f"
                break;
            case "Pfarrer":
                short = "m"
                break;
            case "Pater":
                short = "m"
                break;
            case "Bruder":
                short = "m"
                break;
            case "Schwester":
                short = "f"
                break;
        }
        return short;
    }

    function getMonastery(salutation){
        let short;
        switch(salutation){
            case "Pfarrer":
                short = true
                break;
            case "Pater":
                short = true
                break;
            case "Bruder":
                short = true
                break;
            case "Schwester":
                short = true
                break;
            default:
                short = false;
        }
        return short;
    }

    function setInfoChange(data) {
        let i = infos.map(obj => data.key == obj.key ? {...obj, value: data.value } : obj);
        if(infos.find(e => e.key == data.key) == null){
            i.push(data)
        }
        setInfos(i);
        setChanged(true)
    }

    function validateSomeChanges(infos){
        let accommodationRequired = infos.find(i => i.key == "Uebernachtung")
        if(accommodationRequired?.value == "false"){
            infos = infos.filter(i => i.key != "Unterkunft")
        }
        return infos;
    }

    function checkIn(){
        if(ticket?.checkin?.checkedIn){
            console.log('not first checkin')
        } else {
            console.log('first checkin')
        }
        let i = validateSomeChanges(infos)

        updateParticipationById(ticket.participation.id, {infos: i}, service)
        .then(result => {
            checkInTicket(ticket.id, {badgeType: badgeType}, service)
            .then(res => {
                setTicket(res.ticket)
                setIsLoaded(true)
                navigate('confirmation')
            })
            .catch(err => toast.error(ErrorFormatter(err)))
        })
        .catch(err => toast.error(ErrorFormatter(err)))
    }

    useEffect(() => {
        readTicketById(params.id, service)
            .then(res => {
                readParticipationById(res.ticket.participation.id, service)
                    .then(res1 => {
                        setTicket({ ...res.ticket, participation: res1.participation })
                        setInfos(res1.participation.infos)
                        readEventData(res1.participation.event.id, service)
                            .then(res2 => {
                                setHosts(res2.hosts)
                                setProvisions(res2.provisions)
                                setWorkshops(res2.workshops)
                                setIsLoaded(true)
                            })
                            .catch(err => toast.error(ErrorFormatter(err)))
                    })
                    .catch(err => toast.error(ErrorFormatter(err)))
            })
            .catch(err => toast.error(ErrorFormatter(err)))
    }, [])

    let person, product, event, eventParts, participation, minorAge;
    if(!isLoaded) {
        return <Page><p>Noch nicht geladen</p><Link to="/">Zurück zum Scanner</Link></Page>
    } else if(ticket == null) {
        return (
            <Page>
                <Columns>
                    <Column>
                        <Box>
                            <h3>Kein Ticket gefunden</h3>
                            <Link to="/">Zurück zum Scanner</Link>
                        </Box>
                    </Column>
                </Columns>
            </Page>
        )
    } else {
        ({ product, participation} = ticket);
        ({ person, event, eventParts  } = participation);
    }
    
    let accommSex = getAccommodationSex(person?.salutation);
    let monastery = getMonastery(person?.salutation)
    minorAge = addYears(new Date(person.birthday), 18) > new Date();
    
    return (
        <Page>
            {ticket.checkin?.checkedIn && <div className="myNotification notification is-light is-danger">
                <p>Ticket schon {ticket.checkin.scanCount} Mal eingecheckt.<br />
                Zeitpunkt: {format(new Date(ticket.checkin?.time), 'dd.MM.yyyy, HH:mm:ss')}

                </p>
            </div>}
            <Columns>
                <Column classes="is-4">
                    <Box title={product?.product?.name}>
                        <Participant person={person} helps={participation.helps} minorAge={minorAge}/>
                    </Box>
                </Column>
                <Column>
                    <EditParticipation participation={participation} event={event} eventParts={eventParts} accomSex={accommSex} hosts={hosts} workshops={workshops} infos={infos} provisions={provisions} monastery={monastery} setChange={setInfoChange}/>
                </Column>
            </Columns>
            <Columns>
                <Column>
                    <Box>
                        <BadgeType type={badgeType} setChange={t => setBadgeType(t)}/>
                    </Box>
                </Column>
            </Columns>
            <button className="button is-primary" onClick={() => checkIn()}>Jetzt einchecken</button>
        </Page>
    )
}

export default CheckIn;