import { ApiClient } from './ApiClient'
import { loginRequest } from '../utils/authConfig';

export default class SecureApiClient extends ApiClient {
    instance;
    account;
    constructor(instance, account){
        super();
        this.instance = instance;
        this.account = account;
    }

    async getHeader() {
        if(this.account == null) this.account = this.instance.getAllAccounts()[0];
        const request = {
            scopes: loginRequest.scopes,
            account: this.account,
            
        };
        let token = await this.instance.acquireTokenSilent(request)
            .then(response => {
                return response.accessToken
            })

        let header = {
            headers: {
            Authorization: 'Bearer ' + token
        }}
        return header;
    }

    async get(url) {
        let header = await this.getHeader();
        return super.get(url, header);
    }

    async getMultiple(urls) {
        let header = await this.getHeader();
        return super.getMultiple(urls, header);
    }

    async post(url, data) {
        let header = await this.getHeader();
        return super.post(url, data, header);
    }
    
    async put(url, data) {
        let header = await this.getHeader();
        return super.put(url, data, header);
    }
    
    async del(url) {
        let header = await this.getHeader();
        return super.del(url, header);
    }
    
    async upload(url, data){
        let header = await this.getHeader();
        return super.upload(url, data, header);
    }
}
