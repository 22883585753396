import { LogLevel } from "@azure/msal-browser";
  // Browser check variables
  // If you support IE, our recommendation is that you sign-in using Redirect APIs
  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ");
  const msie11 = ua.indexOf("Trident/");
  const msedge = ua.indexOf("Edge/");
  const firefox = ua.indexOf("Firefox");
  const isIE = msie > 0 || msie11 > 0;
  const isEdge = msedge > 0;
  const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito
  
  // Config object to be passed to Msal on creation
  export const msalConfig = {
      auth: {
          clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
          audience: process.env.REACT_APP_AUTH_CLIENT_ID,
          authority: process.env.REACT_APP_AUTH_AUTHORITY,
          redirectUri: window.location.origin,
          postLogoutRedirectUri: window.location.origin
      },
      cache: {
          storeAuthStateInCookie: isIE || isEdge || isFirefox
      },
      system: {
          loggerOptions: {
              loggerCallback: (level, message, containsPii) => {
                  if (containsPii) {	
                      return;	
                  }
                  switch (level) {	
                      case LogLevel.Error:	
                          console.error(message);	
                          return;	
                      case LogLevel.Info:		
                          return;	
                      case LogLevel.Verbose:	
                          console.debug(message);	
                          return;	
                      case LogLevel.Warning:	
                          console.warn(message);	
                          return;	
                      default:
                          return;
                  }
              }
          }
      }
  };
  
  // Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: [
        process.env.REACT_APP_AUTH_API_SCOPE,
        "openid", "profile", 
        "Calendars.ReadWrite.Shared", "Calendars.ReadWrite",
        "Chat.ReadWrite",
        "email",
        "Group.Read.All", "GroupMember.Read.All",
        "Mail.ReadWrite", "Mail.ReadWrite.Shared", "Mail.Send", "Mail.Send.Shared",
        "Organization.Read.All", "OrgContact.Read.All",
        "Team.ReadBasic.All", "TeamMember.Read.All",
        "User.Read", 
    ]
};