import { Columns, Column, Box } from '../layout';
import React from 'react';

const HostOfParticipant = ({infos, hosts, sex, monastery = false, setChange}) => {

    const handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        setChange({key: name, value: value});
    }
    let host = infos.find(i => i.key == "Unterkunft")

    return (
        <Box title="Unterkunft">
            <select className="input" name="Unterkunft" id="Unterkunft" value={host?.value} onChange={handleInputChange}>
                <option value={0}>Auswählen</option>
                {hosts.map(h => {
                    let name = h.name ?? h?.contact?.nameLine ?? `${h?.contact?.firstName} ${h?.contact?.lastName}`
                    let available = `(${h.freePlaces} von ${h.placesMax} verfügbar)`
                    let disable = (h.freePlaces) < 1 ? true : false 
                    disable = (disable || (h.sex != sex));
                    if(h.sex == "b"){
                        if(monastery){
                            return <option value={h.id}>({h.id}) {name} {available}</option>
                        } else {
                            return <option value={h.id}>({h.id}) {name} {available}</option>
                        }
                    }
                    return <option disabled={disable} value={h.id}>({h.id}) {name} {available}</option>
                })}
            </select>
        </Box>
    )
}

export default HostOfParticipant;