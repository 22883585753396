const { getYear, getMonth, getDay, differenceInYears } = require('date-fns')

function DateFormatter(dateTime) {
    if(dateTime.length < 11) return dateTime;
    let year = dateTime.slice(0,4);
    let month = dateTime.slice(5,7);
    let day = dateTime.slice(8,10);
    let birthday = `${day}.${month}.${year}`;
    return birthday;
}

function DatetimeToInputDate(dateTime) {
    if(dateTime.length < 11) return dateTime;
    let year = dateTime.slice(0,4);
    let month = dateTime.slice(5,7);
    let day = dateTime.slice(8,10);
    let birthday = `${year}-${month}-${day}`;
    return birthday;
}

function InputDateToDate(dateTime) {
    let year = dateTime.slice(0,4);
    let month = dateTime.slice(5,7);
    let day = dateTime.slice(8,10);
    let birthday = `${day}.${month}.${year}`;
    return birthday;
}

function PhoneFormatter(numberOld) {
    let number = numberOld.replace(/ /g,'');
    let firstChar = number.charAt(0);
    let secondChar = number.charAt(1);
    // mit Vorwahl
    if(firstChar === '+') {
        return number;
    } 
    else if(firstChar === '0') {
        // auch mit 00 vorwahl
        if (secondChar === '0') {
            return `+${number.substring(2)}`
        } 
        // ohme vorwahl --> CH nummer
        else {
            return `+41${number.substring(1)}`
        }
    } else {
        return `+41${number}`
    }
}

function getAge(birthdayString){
    let birthday = new Date(Date.parse(birthdayString));
    let today = new Date();
    return differenceInYears(today, birthday);
}

module.exports = {
    DateFormatter,
    DatetimeToInputDate,
    InputDateToDate,
    PhoneFormatter,
    getAge
}

