import React from 'react';

const Badge = ({ticket, data, infos}) => {
    const { person, eventParts } = ticket?.participation;

    let personView, unterkunftView, workshop1View, workshop2View, gruppeView, bischofsbegegnungView = <></>
    personView = <p>{person?.nameLine ?? (person.firstName + ' ' + person.lastName)}</p>
    /*console.log(infos);
    if(data?.Workshop1 != "") workshop1View = <><h6>Workshop 1</h6><p>{data.Workshop1}</p></>
    if(data?.Workshop2 != "") workshop2View = <><h6>Workshop 2</h6><p>{data.Workshop2}</p></>
    if(data.Unterkunft != "") unterkunftView = <><h6>Unterkunft</h6><p>{data.Unterkunft}</p></>
    if(data.Gruppe != "") gruppeView = <><h6>Gruppe</h6><p>{data.Gruppe}</p></>
    if(data.Bischofsbegegnung != "") bischofsbegegnungView = <><h6>Bischofsbegegnung</h6><p>{data.Bischofsbegegnung}</p></>
    return (<>
        {personView}
        {unterkunftView}
        {workshop1View}
        {workshop2View}
        {gruppeView}
        {bischofsbegegnungView}
    </>)*/

    return (<>
        {personView}
        {infos.map(info => {
            const searchRegExp = /\n/g;
            let val = info.value?.replace(searchRegExp, '<br />')
            return (<>
                <h6>{info.key}</h6>
                <p dangerouslySetInnerHTML={{__html: val}}></p>
            </>)
        })}
    </>)
}

export default Badge;