import React from 'react';

const Page = props => {
    const {name, children, fluid, header, withSearch, searchLink, search} = props;
    let container = "container";
    if(fluid){
        container = "container-fluid"
    }

    return(
        <div className={name}>
            <section className="page">
                <div className={container}>
                    {children}
                </div>
            </section>
        </div>
    )
}

export default Page;