import { useForm } from 'react-hook-form';
import * as _ from 'lodash';
import { useState } from 'react';
import Columns from '../../layout/Columns';
import Column from '../../layout/Column';

import Select from '../form/Select';
import TextInput from '../form/TextInput';
import CountrySelect from '../form/CountrySelect';
import DateInput from '../form/DateInput';
import Checkbox from '../form/Checkbox';
import { parse } from 'date-fns';
import CartView from './CartView';
import { Box } from '../../layout';

const CheckoutConfirmation = ({content, infos = [], createCheckout, birthday, cart, checkCoupon, removeCoupon, finalActionInput, productType}) => {
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm();
    const [loading, setLoading] = useState(false)
    const onSubmit = data => {
        setLoading(true)
        //if(productType == "Donation" && data.donationThankYou == null) data.donationThankYou = "null";
        createCheckout(data);
    }

    let groupedInfos = _.groupBy(infos, 'box')
    let className = `button ${loading && "is-loading"}`


    return <div>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Columns>
                <Column>
                    <label className="label">{content.remarks} (optional)</label>
                    <textarea className="input" name="remarks" cols={4} {...register('remarks')} />
                </Column>
            </Columns>
            {
                Object.entries(groupedInfos).map(([key, value]) => {
                    return <>
                        <h4>{key}</h4>
                        <Columns classes="is-multiline">{value.map(info => {
                            let view = <></>
                            switch(info.type){
                                case "select":
                                    view = <Select info={info} content={content} error={errors[info.name]} register={register} />
                                    break;
                                case "countryselect": 
                                    view = <CountrySelect info={info} content={content} error={errors[info.name]} register={register} />
                                    break;
                                case "date":
                                    let validate = {}
                                    let errorTypes = []
                                    view = <DateInput info={info} content={content} error={errors[info.name]} register={register} validate={validate} errorTypes={errorTypes} />
                                    break;
                                case "checkbox":
                                    view = <Checkbox info={info} content={content} error={errors[info.name]} register={register} />
                                    break;
                                default:
                                    view = <TextInput info={info} content={content} error={errors[info.name]} register={register} />
                                    break;
                            }
                            return <Column classes={info?.className ?? "is-full"}>{view}</Column>
                        })}</Columns>
                    </>
                })
            }
            <Box>
            <Columns>
                <Column>
                    <CartView cart={cart} content={content} checkCoupon={checkCoupon} removeCoupon={removeCoupon} cartCoupon={cart?.coupons?.length > 0 ? cart.coupons[0].coupon?.code : null}/>
                </Column>
                <Column>
                    <Select info={{name: "paymentMethod", label: "Zahlungsmethode", options: [{value: "PayNow", label: "TWINT"}, {value: "Bill", label: "Barzahlung"}], required: true}} content={content} error={errors.paymentMethod} register={register} />
                </Column>
            </Columns>
            </Box>

            <Columns>
                <Column>
                    <button type="submit" className={className}>{content[finalActionInput ?? "registerButton"]}</button>
                </Column>
            </Columns>
        </form>
    </div>
}

export default CheckoutConfirmation;