import axios from "axios";
import { flattenDeep } from "lodash";

const loadChoiceProgram = (eventId, service) => {
    return new Promise((resolve, reject) => {
        service.get(`storefront/events/${eventId}/choice-programs`)
        .then(res => {
            let provisions = res.data.provisions;
            let choicePrograms = flattenDeep(res.data.eventParts?.map(eP => eP.choicePrograms))
            resolve({
                provisions: provisions,
                choicePrograms: choicePrograms})
        })
        .catch(err => {
            return reject(err)
        })
    })
}

const loadHosts = (eventId, service) => {
    return new Promise((resolve, reject) => {
        service.get(`storefront/events/${eventId}/hosts`)
        .then(res => resolve(res.data))
        .catch(err => {
            return reject(err)
        })
    })
}

const loadEventInfos = (eventId, service) => {
    return new Promise((resolve, reject) => {
        service.get(`storefront/events/${eventId}/infos`)
        .then(res => resolve(res.data))
        .catch(err => {
            return reject(err)
        })
    })
}

const checkGroupByIdAndName = (id, name, service) => {
    return new Promise((resolve, reject) => {
        service.get(`group-participations/check?id=${id}&name=${name}`)
        .then(res => resolve(res.data))
        .catch(err => {
            return reject(err)
        })
    })
}

const checkIsRegistered = (eventId, contactId, service) => {
    return new Promise((resolve, reject) => {
        service.get(`events/${eventId}/check-participant/${contactId}`)
        .then(res => resolve(res.data))
        .catch(err => {
            return reject(err)
        })
    })
}

export {
    loadChoiceProgram,
    loadHosts,
    loadEventInfos,
    checkGroupByIdAndName,
    checkIsRegistered
}