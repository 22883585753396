const Select = ({register, error, content, info}) => {
    const {name, label, value, required, description, options} = info;
    return <>
        <label className="label">{content[label] ?? label}</label>
        <select className="input" name={name} defaultValue={value} {...register(`${name}`,{ required: required })} aria-invalid={error ? "true" : "false"}>
            {options.map(option => {
                if(option.value == "false") return <option value={false}>{content.no}</option>
                else if(option.value == "true") return <option value={true}>{content.yes}</option>
                return <option disabled={option.disabled} value={option.value}>{content[label] ?? option.label}</option>
            })}
        </select>
        {error && <span>{content.fieldRequired}</span>}
        {description && <span className="help" dangerouslySetInnerHTML={{__html: description}}></span>}
    </>
}

export default Select;