import React from 'react';

const ProductsFilter = ({activeFilter, filters, setFilter}) => {
    return (
        <>
            {filters.map(filter => {
                let cNames = "button filterButton " + (filter.value == activeFilter ? "active" : ""); 
                console.log(cNames);
                return (<button className={cNames} onClick={() => setFilter(filter.value)}>{filter.name}</button>)
            })}
        </>
    )
}

export default ProductsFilter;