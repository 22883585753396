import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { toast } from "react-toastify";

import { ApiContext } from './services/ApiContext';

import { readTicketConfirmationById, readEventData, checkInTicket, updateParticipationById } from './api/CheckinApi';
import { ErrorFormatter } from './utils/ErrorFormatter';

import { Page, Columns, Column, Box } from './layout';

import Badge from './components/Badge';

const Confirmation = props => {

    const { service } = useContext(ApiContext);
    const params = useParams();
    const navigate = useNavigate();

    const [ticket, setTicket] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false);


    useEffect(() => {
        readTicketConfirmationById(params.id, service)
            .then(res => {
                setTicket(res.ticket)
                setIsLoaded(true)
            })
            .catch(err =>toast.error(ErrorFormatter(err)))
    }, []);

    let confirmationView = <p>Wird geladen...</p>
    if(isLoaded){
        confirmationView = <Badge ticket={ticket?.ticket} data={ticket?.infosObject} infos={ticket?.infos}/>
    }
    return (<Page>
        <Columns>
            <Column>
                <Box title="Deine Bestätigung">
                    {confirmationView}
                </Box>
            </Column>
        </Columns>
        <button className="button" onClick={() => navigate("/")}>Zurück zum Scanner</button>
        <button className="button" onClick={() => navigate("/registration")}>Zur Anmeldung</button>
    </Page>)
}

export default Confirmation;