import React, { useEffect, useState } from 'react';
import { Columns, Column, Box } from '../layout';
import ChoiceProgramOfParticipant from './ChoiceProgramOfParticipant';
import HostOfParticipant from './HostOfParticipant';
import EditParticipationInfoBox from './EditParticipationInfoBox';

const EditParticipation = ({event, eventParts, participation, hosts, workshops, provisions, accomSex, infos, monastery, setChange, content = {}}) => {

    const [filteredInfos, setFilteredInfos] = useState([]);
    const [groupedInfos, setGroupedInfos] = useState({});

    useEffect(() => {
        let i = eventInfos.map(info => {
            let show = true // = info.public == true ? true : false;
            /*if(info.conditions != null){
                for(let cond of info.conditions){
                    let obj;
                    switch(cond.type){
                        case "CONTACT":
                            obj = customer;
                            break;
                        case "EVENT":
                            obj = event;
                            break;
                        case "INFO":
                            obj = {[cond.field]: watch(cond.field)};
                            break;
                    }
                    
                    if(obj != null){
                        console.log(cond.field)
                        if((cond.field).includes(".")) {
                            let fields = cond.field.split(".")
                            show = show && checkCondition(cond, obj[fields[0]][fields[1]])
                        } else {
                            show = show && checkCondition(cond, obj[cond.field])
                        }
                    }
                }
            } */
            // add choice programs
            if(workshops?.length > 0) {
                for(let provision of provisions) {
                    if(provision == info.name) {
                        let options = [];
                        for(let choiceProgram of workshops) {
                            for(let p of choiceProgram.provisions) {
                                if(p.key == info.name) {
                                    let available = `(${p.freePlaces ?? '0'} ${content.von ?? "von"} ${p.places} ${content.verfügbar ?? "verfügbar"})`
                                    let disable = (p.freePlaces) < 1 ? true : false 
                                    if(p.places > 0) { options.push({value: choiceProgram.number, label: `${choiceProgram.number} - ${choiceProgram.name} ${available}`, disabled: disable}) }
                                }
                            }
                        }
                        info.options =  _.uniqBy([...options, ...info.options], 'value')
                    }
                }
            }
            // add hosts
            if(hosts?.length > 0) {
                // check sex
                let sex = "m"
                let monastery = false;
                let males = ["Herr", "Bruder", "Pater", "Pfarrer", "Monsieur"];
                let females = ["Frau", "Schwester", "Madame"];
                let churchies = ["Bruder", "Pater", "Pfarrer", "Schwester", "Père"]
                // if(males.includes(participant?.salutation)) sex = "m"
                if(females.includes(customer?.salutation)) sex = "w"
                if(churchies.includes(customer?.salutation)) monastery = true;
                if(info.name == "Unterkunft") {
                    let options = [];
                    for(let host of hosts) {
                        let available = `(${host.freePlaces ?? '0'} ${content.von ?? "von"} ${host.placesMax} ${content.verfügbar ?? "verfügbar"})`
                        let disable = (host.freePlaces) < 1 ? true : false 
                        disable = disable || (host.sex != sex) // && (host.sex == "monastery" && !monastery))
                        if(host.placesMax > 0) { options.push({value: host.id, label: `${host.name} ${available}`, disabled: disable}) }
                    }
                    info.options =  _.uniqBy([...info.options, ...options], 'value')
                }
            }
            if(show) {
                return info
            }
        }).filter(e => e != null)
        setFilteredInfos(i)

        let g = _.groupBy(i, 'box');
        delete g.Multitalent
        setGroupedInfos(g)
    }, [hosts, workshops])

    let customer = participation.person;
    let eventInfos = event?.infos ?? []
    if(eventParts != null){
        for(let eventPart of eventParts){
            eventInfos = eventInfos.concat(eventPart.infos)
        }
        eventInfos = eventInfos.filter(el => el != undefined)
    }

    
    //delete groupedInfos.Unterkunft

    let hostActive = infos.find(i => i.key == "Uebernachtung")

    return (<>
        <Columns>
        {
            Object.keys(groupedInfos).map(key => {
                return (
                    <Column classes="is-4">
                        <Box title={groupedInfos[key][0].box} >
                            <EditParticipationInfoBox fields={eventInfos.filter(i => i.box == key)} data={infos} setChange={setChange}/>
                        </Box>
                    </Column>
                )
            })
        }
            <Column>
                {hostActive?.value == "true" && <HostOfParticipant infos={infos} hosts={hosts} sex={accomSex} setChange={setChange} monastery={monastery} /> }
            </Column>
        </Columns>
    </>)
}

export default EditParticipation;