import React, { useEffect } from "react";
import Columns from "../../layout/Columns";
import Column from "../../layout/Column";

const ProgressState = ({number, name, done, additionalInfo, actionName, action}) => {
    useEffect(() => {

    }, [additionalInfo])
    let progressNumberClass = `progressNumber${done ? ' done' : ''}`
    return <Columns classes="is-mobile progressState is-clearfix">
        <Column>
            <div className={progressNumberClass}>{number}</div>
            <div className="progressTextPrimary">
                <span>{name} {action != null && <button className="button mobile-only is-hidden-tablet" onClick={action}>{actionName}</button> }</span>
            </div>
            <div className="progressTextSecondary">
                <span>{additionalInfo}</span>
            </div>
            {action != null &&
                <button className="button is-hidden-mobile progressButton" onClick={action}>{actionName}</button>
            }
        </Column>        
    </Columns>
}

export default ProgressState;