import React, { useState, useEffect } from 'react';
import { zonedTimeToUtc, utcToZonedTime, format, parse } from 'date-fns-tz'
import PersonalDataForm from './PersonalDataForm';
import ContactFinder from './contacts/ContactFinder';
import { ContactForm } from './contacts/ContactForm';

const PersonalData = props => {
    const { content, formData, setPersonals, message, find, getContact } = props;
    const [edit, setEdit] = useState(null)
    const [state, setState] = useState("new")
    const [contactLocal, setContactLocal] = useState(null)

    useEffect(() => {
        if(formData?.id != null){
            if(state == "new") setState("")
            setContactLocal({id: formData.id})
            setEdit(false)
        } else {
            setState("new")
            setEdit(true)
        }
    }, [formData])

    function readContact(c) {
        if(c.id) getContact(c.id)
        else setContactLocal(c)
    }

    function getContactsAddressText(addresses) {
        if(addresses?.length == 0) return content?.noAddress ?? 'Keine Adresse hinzugefügt'
        let activeAddress = addresses.find(a => a.primary)
        if(activeAddress == null) activeAddress = addresses[0];
        let { addressLine1, addressLine2, plz, city, country } = activeAddress?.address;
        let addressString = `${addressLine1} ${addressLine2}, ${plz} ${city}, ${country}`;
        if(addresses?.length == 2) addressString = `${addressString} \n(+ ${addresses.length - 1} ${content.otherAddress ?? 'weitere Adresse)'}`
        else if(addresses?.length > 2) addressString = `${addressString} \n(+ ${addresses.length - 1} ${content.otherAddresses ?? 'weitere Adressen)'}`
        return addressString;
    }

    let view = <ContactForm registerState={state} contact={formData} content={content} save={d => setPersonals("new", d)}/>
    if(contactLocal == null) view = <ContactFinder find={find} content={content} chooseContact={c => readContact(c)}/>

    if(contactLocal?.id != null && !edit){
        console.log(formData)
        // check if data is complete
        if(formData.firstName == null || formData.lastName == null || formData.salutation == null || formData.birthday == null || formData.city == "") {
            view = <ContactForm registerState="update" contact={formData} content={content} save={d => setPersonals("", d)} message='Eine oder mehrere Angaben fehlen noch' />
        } else {
            let birthday;
            if(formData.birthday?.length > 11) birthday = format(Date.parse(formData?.birthday), 'dd.MM.yyyy', {timeZone: 'Europe/Berlin'});
            view = (
                <>
                    <div className="notification is-success">
                        {content.loginSucessful}
                    </div>
                    <div>
                        <h4>{content.titlePersonals}</h4>
                        <p>{formData.salutation} {formData.title}</p>
                        <p>{formData.firstName} {formData.lastName}</p>
                        <p>{formData.email}</p>
                        <p>{formData.mobile}</p>
                        <p>{birthday}</p>
                        <br />
                        <h4>{content.titleAddress}</h4>
                        <p>{getContactsAddressText(formData.addresses)}</p>

                        <br />
                        <button onClick={() => setPersonals("", formData)} className="button primary">Bestätigen</button>
                        <button onClick={() => {setEdit(true); setState("update")}} className="button secondary">Ändern</button>
                    </div>
                </>
            )
        }
    }

    return view;
}

export default PersonalData