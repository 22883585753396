import React from 'react';

import TicketForm from './TicketForm';

const EventDataForm = props => {
    let { content, doRegister, tickets, choiceProgramProvisions = [], choicePrograms = [], hosts = [], checkCoupon, cartCoupon, removeCoupon, participant, cart, lang, infos, participationData, event } = props;

    return <TicketForm products={tickets} content={content} infos={infos} customer={participant} doRegister={doRegister} participationData={participationData} event={event} choicePrograms={choicePrograms} provisions={choiceProgramProvisions} hosts={hosts} cart={cart}/>
};

export default EventDataForm;