const Product = ({product, addProduct}) => {
    let {type} = product;
    let view = <></>
    if(type == "Ticket" || type == "Travel"){
        view = <Ticket product={product} addProduct={addProduct} />
    } else {
        view = <NormalProduct product={product} addProduct={addProduct} />
    }
    return view;
}

const Ticket = ({product, addProduct}) => {
    let classes = `ticket${product.active ? ' active' : ''}`
    return (
        <div className="column is-half-mobile is-half-desktop">
            <div className={classes} onClick={() => addProduct(product?.id)}>
                <h3>{product.name}</h3>
                <p>{product.days}</p>
                <h6>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF'}).format(product.price)}</h6>
            </div>
        </div>
    )
}

const NormalProduct = ({product, addProduct}) => {
    let classes = `ticket${product.active ? ' active' : ''}`
    return (
        <div className="column is-half-mobile is-half-desktop">
            <div className={classes} onClick={() => addProduct(product?.id)}>
                <h3>{product.name}</h3>
                <p>{product.shortDescription}</p>
                <h6>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF'}).format(product.price)}</h6>
            </div>
        </div>
    )
}

export default Product;