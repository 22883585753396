import React from 'react';

const Columns = props => {
    const { children, classes } = props;
    let className = "column";
    if(classes){
        className = className + " " + classes;
    }
    return(
        <div className={className}>
            {children}
        </div>
    )
}

export default Columns;