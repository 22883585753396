import { useContext, useEffect, useState } from 'react';

import { ApiContext } from './services/ApiContext';
import { createCart, updateCart, addCouponToCart, removeCouponOfCart } from './api/CartApi';

import { Page } from "./layout";
import ProgressState from './components/progress/ProgressState';
import CartProgressState from './components/progress/CartProgressState';
import Cart from './components/registration/Cart';
import PersonalData from './components/registration/PersonalData';
import EventDataForm from './components/registration/EventDataForm';
import CheckoutConfirmation from './components/registration/CheckoutConfirmation';
import { addAddressToContact, createContact, findContacts, readContact, updateContact } from './api/ContactApi';
import { contactsToDataList } from './utils/ContactsToDataList';
import { loadChoiceProgram, loadEventInfos, loadHosts } from './api/EventApi';
import { getAge } from './utils/formatter';
import Payment from './components/registration/checkout/Payment';
import { readCheckout, updateCheckout, createNewCheckout } from './api/CheckoutApi';
import { checkInTicket } from './api/CheckinApi';
import { useNavigate } from 'react-router-dom';
import { addHours, parse } from 'date-fns';


/**
 * 
 * @param {*} props 
 * @description structure of page:
 * - choose product
 * - search for contact
 * - set participation details
 * - choose payment method
 * - check-in
 */
export default function Registration(props) {
    const [cart, setCart] = useState(null);
    const [products, setProducts] = useState([])
    const [processState, setProcessState] = useState(0);
    const [processViewState, setProcessViewState] = useState(0);
    const [address, setAddress] = useState({addressLine1: '', addressLine2: '', plz: '', city: '', country: ''});
    const [customer, setCustomer] = useState({firstName: '', lastName: '', salutation: '', title: '', birthday: '', addresses: []});
    const [participant, setParticipant] = useState({firstName: '', lastName: '', salutation: '', title: '', birthday: '', addresses: []});
    const [eventParts, setEventParts] = useState([])
    const [checkout, setCheckout] = useState(null);
    const [transactionUrl, setTransactionUrl] = useState("")
    
    const [choicePrograms, setChoicePrograms] = useState([]);
    const [choiceProgramProvisions, setChoiceProgramProvisions] = useState([]);
    const [hosts, setHosts] = useState([]);
    const [infos, setInfos] = useState([]);
    const [participationData, setParticipationData] = useState({});

    const [loading, setLoading] = useState(false);

    const [progress, setProgress] = useState(0);

    const content = require('./i8n/de.json');
    const productOptions = window.externalProductOptions ?? require('./data/product-options.json');

    const backend = useContext(ApiContext).service;

    const lang = "DE";

    const navigate = useNavigate();

    async function find(searchTerm){
      return await findContacts(searchTerm, backend)
      .then(res => {
          let { contacts } = res;
          return contactsToDataList(contacts)
      })
      .catch(err => toast.error(err))
  }

    const chooseProducts = (data, session) => {
        setProgress(30);
        let prods = data
        /*
        for(let i = 0; i < data.quantity; i++){
          tickets.push(data.ticket)
        }*/
        setProducts(prods)
        if(cart == null){
          createCart({
            products: data,
            session: session,
            currency: 'CHF'
          }, backend)
          .then(res => {
            setCart(res)
            setProgress(100);
          })
          setProcessState(1);
          setProcessViewState(1);
        } else {
          if(data == cart.products){
            setProgress(100);
          } else {
            updateCart(session, {products: data}, backend)
            .then(res => {
                setCart(res)
                setProgress(100);
            })
          }
          setProcessViewState(processState);
        }
    }

    const saveCustomer = async (state, data) => {
        setProgress(20);
        if(window.choiceProgramEventPartId) {
          loadChoiceProgram(window.choiceProgramEventPartId, backend)
          .then(cP => {
            setChoiceProgramProvisions(cP.provisions);
            setChoicePrograms(cP.choicePrograms)
            setProgress(40);
          })
        }
        if(window.hostId) {
          loadHosts(window.hostId, backend)
          .then(hosts => {
            setHosts(hosts)
            setProgress(60);
          })
        }
        if(window.eventId) {
          loadEventInfos(window.eventId, backend)
          .then(infos => {
            setInfos(infos)
            setProgress(60);
          })
        } else {
          setProgress(60);
        }
        
        switch(state){
          case "new":
            let newContact = await createContact(data, lang, backend);
            setCustomer(newContact);
            setParticipant(newContact);
            break;
          case "update":
            let updatedContact = await updateContactData(data);
            setCustomer(updatedContact);
            setParticipant(updatedContact);
            break;
          case "newParticipant":
            break;
          default:
            //setCustomer(customer);
            setParticipant(customer);
            break;
        }
        let nextProgressState = 2 //products[0]?.type == "Donation" ? 6 : 4;
        setProcessState(nextProgressState);
        setProcessViewState(nextProgressState);
        setProgress(100);
      }
    
      const updateContactData = data => {
        return new Promise(async (resolve, reject) => {
          let changedPersData = {}
          for (const [key, value] of Object.entries(personal)) {
            if(value != data[key] && data[key] != null){
              changedPersData[key] = data[key];
              if(key == "birthday"){
                let birthday = parse(data[key], 'dd.MM.yyyy', new Date());
                birthday = addHours(birthday, 6);
                changedPersData[key] = birthday
              }
            }
          }
          let changedAddrData = {}
          for (const [key, value] of Object.entries(address)) {
            if(value != data[key] && data[key] != null){
              changedAddrData[key] = data[key];
            }
          }
          if(Object.entries(changedPersData).length > 0){
            await updateContact(personal.id, changedPersData, backend)
          }
          if(Object.entries(changedAddrData).length > 0){
            if(address.id != null){
              updateAddress(address.id, changedAddrData, backend)
              .then(res => {
                setAddress(res)
              })
            } else {
              // add address to contact
              addAddressToContact(personal.id, {primary: true, type: "Bill", address: changedAddrData}, backend)
              .then(res => {
                setAddress(changedAddrData)
              })
            }
          }
          readContact(personal.id, backend)
          .then(res => resolve(res))
          .catch(err => reject(err))
        })
    }

    const getContact = id => {
      readContact(id, backend)
      .then(res => setCustomer(res))
      .catch(err => console.log(err))
    }

    const applyCoupon = code => {
        setProgress(30);
        let data = {
          coupon: code
        }
        addCouponToCart(cart.session, data, backend)
        .then(res => {setCart(res); setProgress(100);})
        .catch(err => {console.log(err); setProgress(100);})
    }
    
    const removeCoupon = () => {
        setProgress(30);
        let code = cart.coupons[0].coupon.code
        removeCouponOfCart(cart.session, code, backend)
        .then(res => {setCart(res); setProgress(100);})
        .catch(err => {console.log(err); setProgress(100);})
    }
    
    const saveParticipationData = data => {
        //scrollToComponent();
        setParticipationData(data)
        let nextState = 3 //(group != null && group?.id == null) ? 5 : 6
        setProcessState(nextState);
        setProcessViewState(nextState);
    }

    const doRegister = (data) => {
        let d;
        // Donation
        if(data.donationThankYouType){
          let thankYou = null;
          if(data.donationThankYouType != "None"){
            thankYou = new Date(Date.now())
          }
          d = {
            thankYou: thankYou,
            thankYouType: data.donationThankYouType
          }
          setFinanceData(d)
        } 
        // Ticket or Travel
        else {
          if(participationData?.length > 0) {
            d = [];
            for(let p of participationData) {
              d.push({...p, ...data})
            }
          } else {
            d = {...participationData, ...data}
          }
          setParticipationData(d)
        }
        
        // ticket
        setProgress(30);
        setLoading(true);
        
        createCheckoutWithParticipation(d);
      }
    
    const createCheckoutWithParticipation = (d) => {
        // first create participation
        
        let groupData;

        let tickets = null;
        let donation = null;
        let paymentMethod = ((cart.total == 0) || (cart.total > 500)) ? "Bill" : "PayNow";
        if(participationData?.length > 0) {
          let pax = []
          // "flat" map products
          let prods = [];
          for(let prod of cart?.products){
            for(let i = 0; i < prod.quantity; i++) {
              prods.push(prod?.product?.id ?? prod?.productId ?? null)
            }
          }
          // loop through participations
          for(let i = 0; i < d.length; i++) {
            let participation = d[i];
            let infos = participationDataToInfos(participation)
            pax.push({
              person: participation.participant,
              event: eventsOfProducts(),
              eventParts: eventPartsOfProducts(),
              infos: infos,
              helps: participation.helps == 'true' ? true : false,
              group: groupData,
              product: prods[i]
            })
          }
          tickets = pax.map(p => {return {participation: p}})
        } else if(products[0]?.type != "Donation") {
          let data;
          ({ paymentMethod, ...data } = d);
          let infos = participationDataToInfos(data)
          tickets = [{
            participation: {
              person: participant,
              event: eventsOfProducts(),
              eventParts: eventPartsOfProducts(),
              infos: infos,
              helps: participationData.helps == 'true' ? true : false,
              group: groupData,
              product: cart.products[0]?.product?.id ?? cart.products[0]?.productId ?? null
            },
          }]
        } else if(products[0]?.type == "Donation") {
          donation = d;
        }
        paymentMethod = (cart.total == 0) ? "Bill" : paymentMethod;
        let note = d.remarks ?? undefined;
        
        let checkoutData = { checkout: { customer: customer, cart: cart, transactionMethod: paymentMethod, note: note}, tickets: tickets, donation: donation}
    
        createNewCheckout(checkoutData, backend)
        .then(ch => {
          setCheckout(ch.checkout)
          setTransactionUrl(ch.transactionLink)
          setProcessState(4)
          setProcessViewState(4);
          setProgress(100);
        })
    }

    const validatePayment = async (badgeType) => {
      let ticketId, ticketUUID;
      let action;
      if(checkout.transactionMethod == "Bill") {
        let data = {
          status: "FULFILLED"
        }
        action = updateCheckout(checkout.id, data, backend)
        .then(res => {
          ticketId = res?.cart?.products[0]?.tickets[0]?.id
          ticketUUID = res?.cart?.products[0]?.tickets[0]?.uuid
          checkIn(ticketId, ticketUUID, badgeType)
        })
      } else {
        action = readCheckout(checkout.id, backend)
        .then(res => {
          ticketId = res?.cart?.products[0]?.tickets[0]?.id
          ticketUUID = res?.cart?.products[0]?.tickets[0]?.uuid
          checkIn(ticketId, ticketUUID, badgeType)
        })
      }
    }

    const checkIn = (id, uuid, badgeType) => {
      if(id != null && uuid != null) {
        checkInTicket(id, {badgeType: badgeType}, backend)
        .then(res => {
            navigate(`/checkin/${uuid}/confirmation`)
        })
      } else {
        console.log("I dont know how to handle TODO")
      }
    }

    const participationDataToInfos = (d) => {
      let infos = []
      for (const [key, value] of Object.entries(d)){
        if(key == "volunteer") continue;
        if(key == "helps") continue;
        if(key == "participant") continue;
        infos.push({
          key: key,
          value: (value === true) ? "true" : (value === false) ? "false" : value
        })
      }
      return infos;
    }
  
    const eventsOfProducts = () => {
      let events = []
      for(let product of cart?.products){
        let pId = product?.product?.id ?? product.productId
        let f = productOptions.find(o => o.id == pId)
        if(f) events.push(f.event)
      }
      events = _.uniq(events)
      let ev = events?.length == 1 ? events[0] : events
      return ev ?? window.eventId;
    }
  
    const eventPartsOfProducts = () => {
      let eventParts = []
      for(let product of cart?.products){
        let pId = product?.product?.id ?? product.productId
        let f = productOptions.find(o => o.id == pId)
        if(f) eventParts = eventParts.concat(f.eventParts)
      }
      return _.uniq(eventParts)
    }
  

    useEffect(() => {

    })

    let steps;
    switch(products[0]?.type) {
        case "Travel":
            steps = require('./data/travel-steps.json');
            break;
        case "Donation":
            steps = require('./data/donation-steps.json');
            break;
        default:
            steps = require('./data/ticket-steps.json');
            break;
    }
    
    let progressView = (<>
        {processState > 0 && <CartProgressState number={steps[0].number} name={content[steps[0].name]} done={true} cart={cart} content={content} action={() => setProcessViewState(0)}/>}
        {processState == 1 && <ProgressState number={steps[1]?.number} name={content[steps[1].name]} done={false} />}
        {processState > 1 && <ProgressState number={steps[1]?.number} name={content[steps[1].name]} done={true} additionalInfo={participant.firstName + " " + participant.lastName + " (" + getAge(participant.birthday)+ ")"}/>}
        {(processState >= 2 && products[0]?.type != "Donation") && <ProgressState number={steps[2]?.number} name={content[steps[2].name]} done={(processState > 2)} />}
        {processState >= 3 && <ProgressState number={steps[3]?.number} name={content[steps[3].name]} done={(processState > 3)} />}
        {processState >= 4 && <ProgressState number={steps[4]?.number} name={content[steps[4].name]} done={(processState > 4)} />}
    </>)

    let view;
    let event;
    switch(processViewState){
    case 0:
        view = <><Cart updateCart={chooseProducts} products={productOptions} cart={cart} content={content} cancel={() => setProcessViewState(processState)}/></>
        break;
    case 1:
        view = <PersonalData find={find} setPersonals={saveCustomer} formData={customer} content={content} getContact={getContact}/>
        break;
    case 2:
        event = {
            id: products != null ? products[0].event : null,
            eventParts: products != null ? _.uniq(_.flatten(products.map(prod => prod.eventParts))) : null
        }
        console.log(customer)
        view = <EventDataForm doRegister={saveParticipationData} content={content} tickets={products} choiceProgramProvisions={choiceProgramProvisions} choicePrograms={choicePrograms} hosts={hosts} loading={loading} checkCoupon={applyCoupon} cartCoupon={cart?.coupons[0]?.coupon?.code} removeCoupon={removeCoupon} participant={participant} cart={cart} lang={lang} infos={infos.filter(i => i.appearance == "FORM")} participationData={participationData} event={event} />
        break;
    case 3:
        view = <CheckoutConfirmation createCheckout={doRegister} content={content} infos={infos.filter(i => i.appearance == "SUMMARY")} loading={loading} checkCoupon={applyCoupon} removeCoupon={removeCoupon} cart={cart} lang={lang} event={event} birthday={customer?.birthday} productType={products[0]?.type}/>
        break;
    case 4:
        view = <Payment content={content} loading={loading} checkout={checkout} link={transactionUrl} validate={validatePayment} />
        break;
    default:
        view = <div><p>Nichts geladen</p></div>
        break;
    }

    return (
        <>
            <Page>
                <h2>Anmeldung</h2>
                {progressView}
                {view}
            </Page>
        </>
    )
}