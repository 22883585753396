import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { toast } from "react-toastify";

import { ApiContext } from './services/ApiContext';

import { searchTicket } from './api/CheckinApi';
import { ErrorFormatter } from './utils/ErrorFormatter';

import { Page, Columns, Column, Box } from './layout';

const Tickets = props => {
    const { service } = useContext(ApiContext);
    const params = useParams();

    const [isLoaded, setIsLoaded] = useState(false);
    const [tickets, setTickets] = useState([]);

    useEffect(() => {
        searchTicket(params.search, service)
            .then(res => {
                setTickets(res.tickets)
                setIsLoaded(true)
            })
            .catch(err => toast.error(ErrorFormatter(err)))
    }, [])


    let view = <p>Wird geladen...</p>
    if(isLoaded){
        view = (<>
            {
                tickets.map(ticket => {
                    let ticketUrl = `/checkin/${ticket.uuid}`
                    let name = ticket.participation.person.firstName + ' ' + ticket.participation.person.lastName
                    return <><Link to={ticketUrl} className="tag">{name}</Link><br /></>
                })
            }
        </>)
    }
    return (<Page>
        <Columns>
            <Column>
                <Box title="Suchergebnisse">
                   {view} 
                </Box>
            </Column>
        </Columns>
        <Link to="/">Zurück zum Scanner</Link>
    </Page>)
}

export default Tickets;