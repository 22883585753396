import React from 'react';
import { useForm } from 'react-hook-form';

import Column from '../../layout/Column';
import Columns from '../../layout/Columns';

const CartView = ({cart, content, removeProd, addProd, removeCoup, addCoup, cartCoupon, checkCoupon, removeCoupon }) => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    
    const onSubmit = data => {
        console.log("do nothing")
    }

    const removeProduct = id => {

    }

    const addProduct = id => {

    }

    const applyCoupon = () => {
        let couponCode = watch('couponCode');
        checkCoupon(couponCode);
    }

    return (
        <div className="cart">
            <h3>{content.titleCart}</h3>
                {cart.products?.length > 0 && <h6>{content.products ?? "Produkte"}</h6>}
                {cart.products.map(product => {
                    return <p>{product.quantity > 1 ? '' + product.quantity + 'x ' : ''}{product.product.name} - {new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(product?.price)}</p>
                })}
                {cart.products?.length == 0 && <p>{content.noProductsInCart}</p>}
                {cartCoupon == null ? 
                    <Columns>
                        <Column>
                            <h6>{content.couponCode ?? "Rabattcode"}</h6>
                            <input className="input-long input" name="couponCode" {...register('couponCode')} />
                        </Column>
                        <Column classes="button-bottom">
                            <button type="button" className="button" onClick={applyCoupon}>{content.applyCouponCode}</button>
                        </Column>
                    </Columns> : 
                    <Columns>
                        <Column>
                            <h6>{content.couponCode ?? "Rabattcode"}</h6>
                            <p><i>{cartCoupon}</i> hinzugefügt.</p>
                        </Column>
                        <Column classes="button-bottom">
                            <button type="button" className="button" onClick={removeCoupon}>{content.removeCouponCode}</button>
                        </Column>
                    </Columns>}
                <h6>Total</h6>
                <p>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(cart?.total)}</p>
        </div>
    )
}

export default CartView;