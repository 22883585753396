import { useState, useEffect } from "react"
import { v4 as uuidv4 } from 'uuid';
import { useCookies } from "react-cookie";

import Column from "../../layout/Column";
import Columns from "../../layout/Columns";
import CartSummary from "./CartSummary";
import ProductsList from "./ProductsList"

const Cart = ({products, cart, updateCart, eventParts, cancel, content}) => {
    // map cart to local cart
    const [localProducts, setLocalProducts] = useState(cart?.products?.map(productOfCart => { 
        let product = productOfCart.product ?? productOfCart
        let productOfList = products.find(p => p.id == product.id)
        return {
            ...product,
            eventParts: productOfList?.eventParts,
            days: productOfList?.days,
            multiple: productOfList?.multiple
        }
    }) ?? [])

    const [cookies, setCookie] = useCookies(['CartSession']);
    if(cookies.CartSession == null){
        setCookie('CartSession', uuidv4() , {path: '/', sameSite: 'none'})
    }

    // map groups eventparts to local cart
    useEffect(() => {
        let prods = []
        if(eventParts != null){
            for(let eP of eventParts){
                let productOfList = products.find(p => p.eventParts.includes(eP?.id ?? eP))
                if(productOfList != null) prods.push(productOfList)
            }
            setLocalProducts(prods)
        }
    }, [eventParts])
    
    const toggleProduct = id => {
        let newLocalProducts = [];
        let selectedProduct = localProducts.find(product => product.id == id)
        if(selectedProduct != null){
            if(selectedProduct.selectType == "multi") {
                // Lissabon specific rules
                if(id == 13) newLocalProducts = []
                else if(id == 15) newLocalProducts = localProducts.filter(product => product.id != id && product.id != 14)
                else if(id == 14) newLocalProducts = localProducts.filter(product => product.id != id && product.id != 15)
                else newLocalProducts = localProducts.filter(product => product.id != id)
            }
        } else {
            let toAdd = products.find(product => product.id == id)
            if(toAdd.selectType == "multi"){
                newLocalProducts = [...localProducts];
                newLocalProducts.push(toAdd)
                // add diocese week if vorreise selected
                if(id == 14) {
                    if(localProducts.find(product => product.id == 15) == null){
                        let toAdd = products.find(product => product.id == 15)
                        newLocalProducts.push(toAdd)
                    }
                }
                // new: add vorreise if diocese week is selected
                if(id == 15) {
                    if(localProducts.find(product => product.id == 14) == null){
                        let toAdd = products.find(product => product.id == 14)
                        newLocalProducts.push(toAdd)
                    }
                }
                // add hauptwoche if another week is selected
                if(id != 13){
                    if(localProducts.find(product => product.id == 13) == null){
                        let toAdd = products.find(product => product.id == 13)
                        if(toAdd != undefined) newLocalProducts.push(toAdd)
                    }
                }
            } else {
                newLocalProducts.push(toAdd)
            }
        }
        setLocalProducts(newLocalProducts);
    }

    const confirmCart = (data) => {
        if(data) {
            let product = {...localProducts[0], ...data}
            localProducts[0] = product;
        }
        updateCart(localProducts, cookies.CartSession)
    }

    let preparedProducts = products.map(product => { 
        return {...product, active: (localProducts.find(lP => lP?.id == product.id) != null ? true : false)}
    })

    return (
        <Columns>
            <Column classes="is-8">
                <ProductsList products={preparedProducts} toggleProduct={toggleProduct} />
            </Column>
            <Column classes="is-1"></Column>
            <Column>
                {/*<TicketPriceSelector ticket={ticket} saveTicket={addToCart} />*/}
                <CartSummary products={localProducts} cart={cart} confirm={confirmCart} cancel={cancel} content={content}/>
            </Column>
        </Columns>
    )
}

export default Cart;