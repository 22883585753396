import React, {useEffect, useState} from 'react';
import * as _ from 'lodash';
import { useForm } from "react-hook-form";
import { addYears, compareAsc, parse, subYears } from 'date-fns';

import Columns from '../../layout/Columns';
import Column from '../../layout/Column';

import Select from '../form/Select';
import TextInput from '../form/TextInput';
import CountrySelect from '../form/CountrySelect';
import DateInput from '../form/DateInput';
import Checkbox from '../form/Checkbox';
import { checkCondition } from '../../utils/conditions';

const TicketForm = props => {
    let { content, products, infos = [], choicePrograms, provisions, customer, cart, lang, participationData = {}, doRegister, event, hosts } = props;

    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm();

    const onSubmit = data => {
        console.log(data)
        doRegister(data);
    }

    const parseDate = d => {
        let date;
        if(/([0-9]{2}).([0-9]{2}).([0-9]{4})/g.test(d)){
            date = parse(d, 'dd.MM.yyyy', new Date())
        } else if(/([0-9]{4})-([0-9]{2})-([0-9]{2})/g.test(d)){
            date = parse(d, 'yyyy-MM-dd', new Date())
        }
        return date;
    }

    const getDescription = key => {
        let description = null
        switch(key){
            case "Notfallkontakt":
                description = "Diese Person muss während der Reise in der Schweiz kontaktiert werden können.";
                break;
            case "Vorbereitung":
                description = "Die Teilnahme am nächsten Deutschschweizer Weltjugendtag in Olten, der vom 12. bis 14. Mai 2023 stattfindet, ist die beste Vorbereitung für den Weltjugendtag in Lissabon. Dort kannst du schonmal etwas Weltjugendtags-Luft schnuppern, andere Teilnehmende kennenlernen, alle deine Fragen stellen und unseren Stand besuchen. Sei also unbedingt dabei in Olten! Gerne würde wir dir am WJT in Olten schon etwas für deine Pilgerreise im Sommer mitgeben. Daher müssen wir von dir wissen, ob es dir möglich ist, während diesem Wochenende kurz an unserem Stand vorbeizuschauen (Du musst dazu auch nicht offiziell am WJT teilnehmen)";
                break;
        }
        return description;
    }

    let filteredInfos = infos.map(info => {
        let show = info.public == true ? true : false;
        if(info.conditions != null){
            for(let cond of info.conditions){
                let obj;
                switch(cond.type){
                    case "CONTACT":
                        obj = customer;
                        break;
                    case "EVENT":
                        obj = event;
                        break;
                    case "INFO":
                        obj = {[cond.field]: watch(cond.field)};
                        break;
                }
                
                if(obj != null){
                    if((cond.field).includes(".")) {
                        let fields = cond.field.split(".")
                        show = show && checkCondition(cond, obj[fields[0]][fields[1]])
                    } else {
                        show = show && checkCondition(cond, obj[cond.field])
                    }
                }
            }
        } 
        // add choice programs
        if(choicePrograms?.length > 0) {
            for(let provision of provisions) {
                if(provision == info.name) {
                    let options = [];
                    for(let choiceProgram of choicePrograms) {
                        for(let p of choiceProgram.provisions) {
                            if(p.key == info.name) {
                                let available = `(${p.freePlaces ?? '0'} ${content.von ?? "von"} ${p.places} ${content.verfügbar ?? "verfügbar"})`
                                let disable = (p.freePlaces) < 1 ? true : false 
                                if(p.places > 0) { options.push({value: choiceProgram.number, label: `${choiceProgram.number} - ${choiceProgram.name} ${available}`, disabled: disable}) }
                            }
                        }
                    }
                    info.options =  _.uniqBy([...options, ...info.options], 'value')
                }
            }
        }
        // add hosts
        if(hosts?.length > 0) {
            // check sex
            let sex = "m"
            let monastery = false;
            let males = ["Herr", "Bruder", "Pater", "Pfarrer", "Monsieur"];
            let females = ["Frau", "Schwester", "Madame"];
            let churchies = ["Bruder", "Pater", "Pfarrer", "Schwester", "Père"]
            // if(males.includes(participant?.salutation)) sex = "m"
            if(females.includes(customer?.salutation)) sex = "w"
            if(churchies.includes(customer?.salutation)) monastery = true;
            if(info.name == "Unterkunft") {
                let options = [];
                for(let host of hosts) {
                    let available = `(${host.freePlaces ?? '0'} ${content.von ?? "von"} ${host.placesMax} ${content.verfügbar ?? "verfügbar"})`
                    let disable = (host.freePlaces) < 1 ? true : false 
                    disable = disable || (host.sex != sex) // && (host.sex == "monastery" && !monastery))
                    if(host.placesMax > 0) { options.push({value: host.id, label: `${host.name} ${available}`, disabled: disable}) }
                }
                info.options =  _.uniqBy([...info.options, ...options], 'value')
            }
        }
        if(show) {
            return info
        }
    }).filter(e => e != null)

    // add helps
    if(products?.length > 0) {
        if(products[0].type == "Ticket") {
            let indexWhereToInsert = filteredInfos.findIndex(v => v.box == "Helfer");

            filteredInfos.splice(indexWhereToInsert, 0, {
                appearance: "FORM",
                box: "Helfer",
                label: "Ich möchte als Multitalänt mithelfen.",
                name: "helps",
                public: true,
                type: "select",
                trueText: "Wir freuen uns über deine Teilnahme als Multitalänt und danken für deine Mithilfe. \nDer Rabatt wird erst bei der Einteilung berechnet. Vorerst verrechnen wir dir den vollen Ticketpreis. Du erhälst beim Abholen deines Plans den errechneten Betrag Bar zurück. Vielen Dank für dein Verständnis.",
                options: [{value: "false", label: "Nein"}, {value: "true", label: "Ja"}]
            })
        }
    }

    let groupedInfos = _.groupBy(filteredInfos, 'box')

    return (
        <div>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                {
                    Object.entries(groupedInfos).map(([key, value]) => {
                        let description = getDescription(key)
                        return <>
                            <h4>{content[key] ?? key}</h4>
                            {description != null && <><p>{description}</p><br /></>}
                            <Columns classes="is-multiline">{value.map(info => {
                                let view = <></>
                                switch(info.type){
                                    case "select":
                                        view = <Select initial={participationData[info.name]} info={info} content={content} error={errors[info.name]} register={register} watch={watch}/>
                                        break;
                                    case "countryselect": 
                                        view = <CountrySelect initial={participationData[info.name]} info={info} content={content} error={errors[info.name]} register={register} />
                                        break;
                                    case "date":
                                        let validate = {}
                                        let errorTypes = []
                                        /*
                                        if(info.name == "PassportIssuingDate"){
                                            validate = {
                                                minDate: v => { let d = parseDate(v); return (compareAsc(addYears(d, 10), new Date(Date.now())) > -1); },
                                                maxDate: v => { let d = parseDate(v); return (compareAsc(d, new Date(Date.now())) < 1) },
                                            }
                                            errorTypes = [
                                                {name: "minDate", message: "Das Ausstelldatum darf nicht länger als 10 Jahre zurückliegen."},
                                                {name: "maxDate", message: "Das Ausstelldatum darf nicht in der Zukunft liegen."},
                                            ]
                                        } else if(info.name == "PassportExpiryDate"){
                                            validate = {
                                                maxDate: v => { let d = parseDate(v); return (compareAsc(subYears(d, 10), new Date(Date.now())) < 1) },
                                                minDate: v => { let d = parseDate(v); return (compareAsc(d, new Date(Date.now())) > -1) },
                                            }
                                            errorTypes = [
                                                {name: "minDate", message: "Das Gültigkeitsdatum darf nicht in der Vergangenheit liegen."},
                                                {name: "maxDate", message: "Das Gültigkeitsdatum darf nicht mehr als 10 Jahre in der Zukunft liegen."},
                                            ]
                                        }*/
                                        view = <DateInput initial={participationData[info.name]} info={info} content={content} error={errors[info.name]} register={register} validate={validate} errorTypes={errorTypes} />
                                        break;
                                    case "checkbox":
                                        view = <Checkbox initial={participationData[info.name]} info={info} content={content} error={errors[info.name]} register={register} />
                                        break;
                                    default:
                                        view = <TextInput initial={participationData[info.name]} info={info} content={content} error={errors[info.name]} register={register} />
                                        break;
                                }
                                return <Column classes={info?.className ?? "is-full"}>{view}</Column>
                            })}</Columns>
                        </>
                    })
                }
                <input type="submit" value={content.nextButton ?? "Weiter"} />
            </form>
        </div>
    );
};

export default TicketForm;