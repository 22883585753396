const formatInput = text => {
    let value;
    switch(text){
        case "true":
            value = "ja";
            break;
        case "false":
            value = "nein";
            break;
        case "":
            value = "-";
            break;
        default: 
            value = text;
            break;
    }
    return value;
}

function extractId(input){
    if(input.includes('(') && input.includes(')')){
        let vars = input.split('(');
        input = vars[1];
        vars = input.split(')');
        input = vars[0];
        input = parseInt(input);
    }
    return input;
}

export {
    formatInput,
    extractId
}