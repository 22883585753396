import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { getYear, parse } from 'date-fns';
import { DatetimeToInputDate, InputDateToDate, PhoneFormatter } from '../../utils/formatter';
import { Columns, Column } from '../../layout';

const PersonalDataForm = props => {
    let { content, formData, doRegister, registerState = "new", message, sameAddress = false, setSameAddress } = props;
    const { register, handleSubmit, watch, formState: { errors }, reset, getValues} = useForm();
    if(formData.birthday) formData.birthday = DatetimeToInputDate(formData.birthday);

    const onSubmit = data => {
      data.birthday = InputDateToDate(data.birthday);
      data.mobile = PhoneFormatter(data.mobile);
      doRegister(registerState, data);
    }

    useEffect(() => {
      let d = getValues();
      reset({
        ...d,
        ...formData
      })
    }, [formData])

    return (
      <div>
        {message && <div className="notification is-warning is-light">
          {message}
        </div>}
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <Columns>
            <Column>
              <label className="label">{content.salutation}</label>
              <select className="input" name="salutation" defaultValue={formData.salutation} {...register('salutation',{ required: true })}>
                <option value="Herr">{content.mister}</option>
                <option value="Frau">{content.miss}</option>
                <option value="Bruder">{content.brother}</option>
                <option value="Schwester">{content.sister}</option>
                <option value="Pater">{content.pater}</option>
                <option value="Pfarrer">{content.priest}</option>
              </select>
              {errors.salutation && <span>{content.fieldRequired}</span>}
            </Column>
            <Column>
              <label className="label">{content.title}</label>
              <input className="input" type="text" name="title" defaultValue={formData.title} {...register('title')} />
            </Column>
          </Columns>
          <Columns>
            <Column>
              <label className="label">{content.firstName}</label>
              <input className="input" type="text" name="firstName" defaultValue={formData.firstName} {...register('firstName', { required: true })} />
              {errors.firstName && <span>{content.fieldRequired}</span>}
            </Column>
            <Column>
              <label className="label">{content.lastName}</label>
              <input className="input" type="text" name="lastName" defaultValue={formData.lastName} {...register('lastName',{ required: true })} />
              {errors.lastName && <span>{content.fieldRequired}</span>}
            </Column>
          </Columns>
          <Columns>
            <Column>
              <label className="label">{content.email}</label>
              <input className="input" type="email" name="email" defaultValue={formData.email} {...register('email',{ required: true })} />
              {errors.email && <span>{content.fieldRequired}</span>}
            </Column>
          </Columns>
          <Columns>
            <Column>
              <label className="label">{content.mobile}</label>
              <input className="input" type="phone" name="mobile" defaultValue={formData.mobile} {...register('mobile',{ required: true })} />
              {errors.mobile && <span>{content.fieldRequired}</span>}
            </Column>
          </Columns>
          <Columns>
            <Column>
              <label className="label">{content.birthday}</label>
              <input type="date" className="datechk input" name="birthday" defaultValue={formData.birthday} {...register('birthday',{ required: true, validate: {
                minAge: v => { let birthday = parse(v, 'yyyy-mm-dd', new Date()); return getYear(birthday) < 2012 },
                maxAge: v => { let birthday = parse(v, 'yyyy-mm-dd', new Date()); return getYear(birthday) > 1940 },
              } })} />
              {errors.birthday?.type == 'required' && <span>{content.fieldRequired}</span>}
              {errors.birthday?.type == 'minAge' && <span>Eine Teilnahme ist erst ab 16 Jahren möglich.</span>}
              {errors.birthday?.type == 'maxAge' && <span>Bitte gebe das Jahr mit einer vierstelligen Ziffer ein.</span>}
            </Column>
          </Columns>
          {sameAddress && 
            <>
              <Columns>
                <Column>
                  <label className="label">{content.sameAddress}</label>
                  <select className="input" name="sameAddress" defaultValue={false} onChange={(ev) => setSameAddress(ev.target.value)}>
                    <option value={false}>{content.no}</option>
                    <option value={true}>{content.yes}</option>
                  </select>
                </Column>
              </Columns>
            </>
          }
          <Columns>
            <Column>
              <label className="label">{content.addressLine1}</label>
              <input className="input" type="text" name="addressLine1" defaultValue={formData.addressLine1} {...register('addressLine1',{ required: true })} />
              {errors.addressLine1 && <span>{content.fieldRequired}</span>}
            </Column>
          </Columns>
          <Columns>
            <Column>
              <label className="label">{content.addressLine2}</label>
              <input className="input" type="text" name="addressLine2" defaultValue={formData.addressLine2} {...register('addressLine2')} />
            </Column>
          </Columns>
          <Columns>
            <Column>
              <label className="label">{content.plz}</label>
              <input className="input" type="text" name="plz" defaultValue={formData.plz} {...register('plz',{ required: true })} />
              {errors.plz && <span>{content.fieldRequired}</span>}
            </Column>
            <Column>
              <label className="label">{content.city}</label>
              <input className="input" type="text" name="city" defaultValue={formData.city} {...register('city',{ required: true })} />
              {errors.city && <span>{content.fieldRequired}</span>}
            </Column>
          </Columns>
          <Columns>
            <Column>
              <label className="label">{content.country}</label>
              <select className="input" name="country" defaultValue={formData.country} {...register('country',{ required: true })}>
                <option value="CH">{content.switzerland}</option>
                <option value="DE">{content.germany}</option>
                <option value="FR">{content.france}</option>
                <option value="IT">{content.italy}</option>
                <option value="LI">{content.liechtenstein}</option>
                <option value="AT">{content.austria}</option>
              </select>
              {errors.salutation && <span>{content.fieldRequired}</span>}
            </Column>
          </Columns>
          <Columns>
            <Column>
              <input type="submit" value={content.startButton} {...register('registerButton')} />
            </Column>
          </Columns>
        </form>
      </div>
    );
};

export default PersonalDataForm;