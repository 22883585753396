export function contactsToDataList(contacts) {
    let contactList = [];
    for (let contact of contacts) {
        let nameLine;
        if(contact == null) continue;
        let { id, type, firstName, lastName, salutation, name, section } = contact;
        switch(type){
            case "Person":
                nameLine = `${firstName ?? ''}${firstName ? ' ' : ''}${lastName ?? ''}`
                break;
            case "Business":
                nameLine = `${name ?? ''} ${section ?? ''}`
                break;
            case "Family":
                nameLine = `${salutation ?? ''} ${lastName ?? ''}`
                break;
        }
        contactList.push({
            ...contact,
            nameLine: nameLine
        });
    }
    console.log(contactList)
    return contactList;
}
