import { useState } from "react"
import { Column, Columns } from "../../../layout";
import { extractId } from "../../../utils/FormatInput";

const ContactFinder = ({find, content, chooseContact}) => {
    const [name, setName] = useState("");
    const [contacts, setContacts] = useState([])

    function handleSubmit(e) {
        e.preventDefault();
    }

    function findContact(name) {
        setName(name)
        find(name)
        .then(contacts => setContacts(contacts))
    }

    function existingContact() {
        let id = extractId(name)
        if(id != NaN) chooseContact({id: id})
        newContact()
    }

    function newContact() {
        chooseContact({})
    }

    return (<form className="form" onSubmit={handleSubmit}>
        <Columns>
            <Column>
            <label className="label" for="name">{content?.searchContact ?? "Name"}</label>
            <input className="input" list="name-list" id="name" name="name" type="text" value={name} onChange={e => findContact(e.target.value)} />
            <datalist id="name-list">{contacts.map(contact => { return <option value={`${contact.nameLine} (${contact?.id})`}>{`${contact.nameLine} (${contact?.id})`}</option>})}</datalist>
            </Column>
        </Columns>
        <Columns>
            <Column>
                <button onClick={existingContact} className="button is-primary">Auswählen</button>
                <button onClick={newContact} className="button is-secondary">Neu erstellen</button>
            </Column>
        </Columns>
    </form>)
}

export default ContactFinder;