const { parse } = require("date-fns");

export function checkCondition(condition, field){
    let isTruthy = true;
    if(condition.gt){
        isTruthy = isTruthy && checkDateGreater(field, condition.gt)
    }
    if(condition.in){

    }
    if(condition.lt){
        isTruthy = isTruthy && checkDateLower(field, condition.lt)
    }
    if(condition.notIn){

    }
    if(condition.is){
        isTruthy = condition.is == field;
    }
    if(condition.isNot){
        isTruthy = condition.isNot != field;
    }
    if(condition.includes){
        if(parseInt(condition.includes) != NaN){
            isTruthy = isTruthy && checkIncludes(field, parseInt(condition.includes))
        } else {
            isTruthy = isTruthy && checkIncludes(field, condition.includes)
        }
    }
    if(condition.notIncludes){
        if(parseInt(condition.notIncludes) != NaN){
            isTruthy = isTruthy && checkNotIncludes(field, parseInt(condition.notIncludes))
        } else {
            isTruthy = isTruthy && checkNotIncludes(field, condition.notIncludes)
        }
    }
    return isTruthy;
}

function checkDateLower(date, reference){
    let compDate = new Date(date)
    let refDate = parse(reference, 'yyyy-MM-dd', new Date())
    return (compDate < refDate)
}

function checkDateGreater(date, reference){
    let compDate = new Date(date)
    let refDate = parse(reference, 'yyyy-MM-dd', new Date())
    return (compDate > refDate)
}

function checkNumberLower(num, reference){
    return num < reference;
}

function checkNumberGreater(num, reference){
    return num > reference;
}

function checkNumberIn(num, inString){
    let inList = inString.split(",").map(n => parseInt(n))
    return inList.includes(num)
}

function checkNumberNotIn(num, inString){
    let inList = inString.split(",").map(n => parseInt(n))
    return !inList.includes(num)
}

function checkStringIn(str, inString){
    let inList = inString.split(",")
    return inList.includes(str)
}

function checkStringNotIn(str, inString){
    let inList = inString.split(",")
    return !inList.includes(str)
}

function checkIncludes(inList, val){
    return inList.includes(val)
}

function checkNotIncludes(inList, val){
    return !inList.includes(val)
}