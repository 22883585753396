import React from "react";
import { Route, Routes } from "react-router-dom";

import CheckIn from "./CheckIn";
import Confirmation from "./Confirmation";
import Registration from "./Registration";
import CheckinScanner from "./Scanner";
import Tickets from "./Tickets";

export default function AppRoutes({ appProps }) {
  return (
    <Routes>
      <Route path="/checkin/:id" element={<CheckIn />} />
      <Route path="/checkin/:id/confirmation" element={<Confirmation />} />
      <Route path="/search/:search" element={<Tickets />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="" element={<CheckinScanner />} appProps={appProps}/>
    </Routes>
  );
}