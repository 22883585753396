export function ErrorFormatter(error) {
    if(typeof error == 'string'){
        return <p>{error}</p>
    } else {
        if(error?.response != null){
            return <p><strong>Status: {error.response.status} - {error.response.statusText}</strong> <br />Nachricht: {error.response.data}</p>
        } else {
            return <p>{error.toString()}</p>
        }
    }
}