import React from 'react';
import { format } from 'date-fns'

const Participant = props => {
    const { person, helps, minorAge } = props;
    let activeAddresses = person.addresses.filter(address => address?.active == true)
    let activeAddress = activeAddresses[0]?.address != null ? activeAddresses[0]?.address : person.addresses[0]?.address;
    return (
        <div className="contactSummary">
            {props.helps && <div className="tag active">Helfer</div>} {minorAge && <div className="tag is-danger">minderjährig</div>}
            <h6>Person</h6>
            <p>{person.salutation} {person.title != null ? person.title + ' ' : ''}{person.nameLine ?? (person.firstName + ' ' + person.lastName)}</p>
            <p>{format(new Date(person.birthday), 'dd.MM.yyyy')}</p>
            <p>{person.email ?? person.emails[0]}</p>
            <p>{person.mobile}</p>
            <h6>Adresse</h6>
            {activeAddress?.addressLine1 && <p>{activeAddress.addressLine1}</p> }
            {activeAddress && <p>{activeAddress.plz} {activeAddress.city}</p> }
        </div>
    )
}

export default Participant;