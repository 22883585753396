import axios from "axios"


const createCheckout = (tickets, checkout, service) => {
    return new Promise((resolve, reject) => {
        let checkoutData = {
            checkout: checkout,
            tickets: tickets
        }
        service.post('checkout', checkoutData)
        .then(res => {
            resolve(res.data)
        })
    })
}

const createNewCheckout = (checkout, service) => {
    return new Promise((resolve, reject) => {
        service.post('checkout', checkout)
        .then(res => {
            resolve(res.data)
        })
    })
}

const updateCheckout = (id, data, service) => {
    return new Promise((resolve, reject) => {
        service.put('checkout/' + id, data)
        .then(res => {
            resolve(res.data)
        })
        .catch(err => reject(err))
    })
}

const readCheckout = (id, service) => {
    return new Promise((resolve, reject) => {
        service.get('checkout/' + id, data)
        .then(res => {
            resolve(res.data)
        })
        .catch(err => reject(err))
    })
}

export {
    createCheckout,
    createNewCheckout,
    updateCheckout,
    readCheckout
}