import React, {useEffect} from 'react';
import { Router, BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import history from "./services/history";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import './assets/css/importedStyles.scss';
import './assets/css/App.scss';

function App(props) {

    useEffect((() => {
        if(process.env.REACT_APP_PAGE_TITLE != undefined) document.title = process.env.REACT_APP_PAGE_TITLE
    }), [])

    return (
        <div className="App">
            <BrowserRouter history={history}>
                <div className="mainPage">
                    <Routes/>
                </div>
            </BrowserRouter>
            <ToastContainer />
        </div>
    );
}

export default App;