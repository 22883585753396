import React, {useEffect, useState} from "react";
import CartView from "../registration/CartView";
import ProgressState from "./ProgressState"

const CartProgressState = ({number, name, done, cart, content, action, actionName}) => {
    const [change, setChange] = useState(false);
    console.log(cart)
    let cartString = cart?.products != null ? `${cart?.products[0]?.quantity}x ${cart?.products[0]?.product?.name} - ${new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(cart?.total) }` : '';
    if(cart?.products?.filter(pr => pr?.product?.type == "Ticket")?.length > 0){
        let productsCount = 0;
        for(let product of cart?.products){
            productsCount += product?.quantity ?? 1
        }
        if(productsCount > 1){
            cartString = `${productsCount} Tickets - Total: ${new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(cart?.total) }`
        } else {
            cartString = `${cart?.products[0]?.product?.name} - Total: ${new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(cart?.total) }`
        }
    } else if(cart?.products?.filter(pr => pr?.product?.type == "Travel")?.length > 0){
        let productsCount = 0;
        for(let product of cart?.products){
            productsCount += product?.quantity ?? 1
        }
        if(productsCount > 1){
            cartString = `${productsCount} Reisewochen - Total: ${new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(cart?.total) }`
        } else {
            cartString = `${cart?.products[0]?.product?.name} - Total: ${new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(cart?.total) }`
        }
    }
    useEffect(() => {
        cartString = `${cart?.products[0].quantity}x ${cart?.products[0]?.product?.name} - ${new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(cart?.total) }`
    }, [cart]);

    if(change) {
        return <CartView cart={cart} content={content} />
    }
    return <ProgressState number={number} done={done} name={name} additionalInfo={cartString} actionName={"Ändern"} action={action}/>
}

export default CartProgressState;