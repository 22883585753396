import QRCode from 'react-qr-code';
import { Column, Columns } from '../../../layout';
import { useState } from 'react';

const Payment = ({checkout, link, validate}) => {
    const [badgeType, setBadgeType] = useState("email");
    let view = <></>
    if(checkout.transactionMethod == "PayNow") view = <QRCode value={link} />
    else view = <p>Jetzt Zahlung entgegen nehmen.</p>

    let badgeTypeView = <>
        <h6>Bestätigung</h6>
        <select className="input" onChange={e => setBadgeType(e.target.value)}>
            <option value="email">E-Mail</option>
            <option value="SMS">SMS</option>
            <option value="print">Druck</option>
        </select>
    </>

    return <>
        <Columns>
            <Column>
                {view}
            </Column>
        </Columns>
        <Columns>
            <Column>
                {badgeTypeView}
                <br />
            </Column>
        </Columns>
        <button onClick={() => validate(badgeType)} className="button">Bestätigen</button>
    </>
}

export default Payment;