import React, {  useState } from 'react'
import { QrReader } from 'react-qr-reader'
import { useNavigate } from 'react-router-dom';
import { Column, Columns, Page } from './layout';
 
const CheckinScanner = props => {
    const navigate = useNavigate();

    const [result, setResult] = useState('No result')
    const [ticketId, setTicketId] = useState('')

    function handleScan(d) {
        let data = d?.text
        if (data) {
            setResult(data)
            setTicketId(data)
            navigate('/checkin/' + data);
        }
    }

    const handleError = err => {
        console.error(err)
    }

    const handleSubmit = event => {
        if (event) {
            navigate('/search/' + ticketId);
        }
        event.preventDefault();
    }

    const handleChangeTicketId = event => {
        setTicketId(event.target.value)
    }

    return (
        <div>
            <Page>
                <h2>Scanner</h2>
                <QrReader
                    delay={300}
                    onError={handleError}
                    onResult={data => handleScan(data)}
                    style={{ width: '100%' }}
                    constraints={{
                        facingMode: 'environment'}
                    }
                />
                <form onSubmit={handleSubmit}>
                    <Columns>
                        <Column>
                        <input type="text" 
                            value={ticketId}
                            onChange={handleChangeTicketId}
                            className="input"
                            placeholder="ID"
                        />
                        </Column>
                        <Column>
                            <input className="button" type="submit" value="Suchen" />
                        </Column>
                    </Columns>
                </form>
                <br />
                <button className="button" onClick={() => navigate("/registration")}>Person anmelden</button>
          </Page>
      </div>
    )
}

export default CheckinScanner;