import { useForm } from "react-hook-form"

import { getYear, parse } from "date-fns";
import { useEffect, useState } from "react";
import { ContactsAddresses } from "./ContactsAddresses";
import { Column, Columns } from "../../../layout";
import { InputDateToDate, PhoneFormatter } from "../../../utils/formatter";

export const ContactForm = ({contact, save, cancel, content, message, setSameAddress, sameAddress, monasteryPerson, setMonasteryPerson}) => {
    const { register, handleSubmit, watch, formState: { errors }, reset, getValues, setValue} = useForm();

    const [addresses, setAddresses] = useState(contact.addresses ?? [])

    const onSubmit = fd => {
        let data = getValues()
        data.addresses = mapAddresses(data.addresses)
        data.birthday = InputDateToDate(data.birthday);
        data.mobile = PhoneFormatter(data.mobile);
        //data.addresses = addresses;
        save(data);
    }

    function mapAddresses(addresses) {
        let as = addresses;
        if(addresses?.length > 0) {
            as = addresses.map(address => {
                if(address.addressLine1 != null && address.primary == null) {
                    let a = {
                        primary: true,
                        type: 'Bill',
                        address: address
                    }
                    return a;
                }
            })
        }
        return as;
    }

    function selectAddress(address) {
        let newAddresses = addresses.map(a => { return { ...a, primary: address.id == a.id }})
        setAddresses(newAddresses)
    }

    function salutationChange(event) {
        let isMonastery = ["Bruder", "Schwester", "Pater", "Pfarrer"].includes(event.target.value)
        if(isMonastery && !monasteryPerson) {
            setMonasteryPerson("true");
        }
    }
  
    useEffect(() => {
        setAddresses(contact.addresses)
        let d = getValues();
        reset({
          ...d,
          ...contact,
          //birthday: contact?.birthday ? parse(contact?.birthday, 'yyyy-MM-dd',  new Date()) : null
        })
    }, [contact])

    console.log(watch())
  
    return (
        <div>
            {message && <div className="notification is-warning is-light">
                {message}
            </div>}
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <Columns>
                    <Column>
                        <label className="label">{content.salutation}</label>
                        <select className="input" name="salutation" defaultValue={contact.salutation} {...register('salutation',{ required: true, onChange: salutationChange })}>
                            <option value="Herr">{content.mister}</option>
                            <option value="Frau">{content.miss}</option>
                            <option value="Bruder">{content.brother}</option>
                            <option value="Schwester">{content.sister}</option>
                            <option value="Pater">{content.pater}</option>
                            <option value="Pfarrer">{content.priest}</option>
                        </select>
                        {errors.salutation && <span>{content.fieldRequired}</span>}
                    </Column>
                    <Column>
                        <label className="label">{content.title}</label>
                        <input className="input" type="text" name="title" defaultValue={contact.title} {...register('title')} />
                    </Column>
                </Columns>
                <Columns>
                    <Column>
                        <label className="label">{content?.monasteryPerson ?? "Ordensperson / Priester?"}</label>
                        <select className="input" name="monasteryPerson" onChange={e => setMonasteryPerson(e.target.value)} value={monasteryPerson}>
                            <option value={false}>{content.no ?? "nein"}</option>
                            <option value={true}>{content.true ?? "ja"}</option>
                        </select>
                    </Column>
                </Columns>
                <Columns>
                    <Column>
                        <label className="label">{content.firstName}</label>
                        <input className="input" type="text" name="firstName" defaultValue={contact.firstName} {...register('firstName', { required: true })} />
                        {errors.firstName && <span>{content.fieldRequired}</span>}
                    </Column>
                    <Column>
                        <label className="label">{content.lastName}</label>
                        <input className="input" type="text" name="lastName" defaultValue={contact.lastName} {...register('lastName',{ required: true })} />
                        {errors.lastName && <span>{content.fieldRequired}</span>}
                    </Column>
                </Columns>
                <Columns>
                    <Column>
                        <label className="label">{content.email}</label>
                        <input className="input" type="email" name="email" defaultValue={contact.email} {...register('email',{ required: true })} />
                        {errors.email && <span>{content.fieldRequired}</span>}
                    </Column>
                </Columns>
                <Columns>
                    <Column>
                        <label className="label">{content.mobile}</label>
                        <input className="input" type="phone" name="mobile" defaultValue={contact.mobile} {...register('mobile',{ required: true })} />
                        {errors.mobile && <span>{content.fieldRequired}</span>}
                    </Column>
                </Columns>
                <Columns>
                    <Column>
                        <label className="label">{content.birthday}</label>
                        <input type="date" className="datechk input" name="birthday" defaultValue={contact.birthday} {...register('birthday',{ required: true, validate: {
                            minAge: v => { let birthday = parse(v, 'yyyy-MM-dd', new Date()); console.log(birthday); return getYear(birthday) < 2012 },
                            toOld: v => { let birthday = parse(v, 'yyyy-MM-dd', new Date()); console.log(birthday); return (getYear(birthday) > 1987  || monasteryPerson == "true")},
                            maxAge: v => { let birthday = parse(v, 'yyyy-MM-dd', new Date()); return getYear(birthday) > 1940},
                        },
                        value: contact.birthday })} />
                        {errors.birthday?.type == 'required' && <span>{content.fieldRequired}</span>}
                        {errors.birthday?.type == 'minAge' && <span>{content.minAgeError ?? "Eine Teilnahme ist erst ab 16 Jahren möglich."}</span>}
                        {errors.birthday?.type == 'toOld' && <span>{content.toOldError ?? "Eine Teilnahme ist bis 35 Jahren möglich."}</span>}
                        {errors.birthday?.type == 'maxAge' && <span>{content.maxAgeError ?? "Bitte gebe das Jahr mit einer vierstelligen Ziffer ein."}</span>}
                    </Column>
                </Columns>
                <ContactsAddresses addresses={addresses} addAddress={() => {}} deleteAddress={() => {}} selectAddress={selectAddress} content={content} register={register}/>

                <Columns>
                    {cancel && <Column>
                        <button className="button is-pulled-left" onClick={cancel}>{content.cancel ?? "Abbrechen"}</button>
                    </Column> }
                    <Column>
                        <input className="button is-pulled-right" type="submit" value={content.startButton} {...register('registerButton')} />
                    </Column>
                </Columns>
            </form>
        </div>
      );
}