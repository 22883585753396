import React from 'react';

const Box = props => {

    return (
        <div className="box" ref={props.refProp}>
            <h2>{props.title}</h2>
            {props.children}
        </div>
    )
}

export default Box;