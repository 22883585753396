import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './assets/css/index.scss';

import { ApiContext } from './services/ApiContext';
import SecureApiClient from './services/SecureApiClient';

import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { msalConfig, loginRequest } from './authConfig'

const pca = new PublicClientApplication(msalConfig);

const authRequest = {
  ...loginRequest
};

const container = document.getElementById('root');

function ErrorComponent({error}) {
  return <p>An Error Occurred: {error}</p>;
}

function LoadingComponent() {
  return <p>Authentication in progress...</p>;
}

ReactDOM.render(
  <React.StrictMode>
      <MsalProvider instance={pca}>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={LoadingComponent}>
            <ApiContext.Provider value={{service: new SecureApiClient(pca, pca.getAllAccounts()[0])}}>
              <App />
            </ApiContext.Provider>
        </MsalAuthenticationTemplate> 
      </MsalProvider>
  </React.StrictMode>,
  container
);