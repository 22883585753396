import { flattenDeep } from "lodash"

function readTicketById(id, service){
    return new Promise((resolve, reject) => {
        let url = `tickets/uuid/${id}`
        service.get(url)
            .then(res => {
                resolve({
                    ticket: res.data
                })
            })
            .catch(err => { return reject(err)})
        })
}

function searchTicket(searchId, service){
    return new Promise((resolve, reject) => {
        let url = `tickets/uuid/search/${searchId}`
        service.get(url)
            .then(res => {
                resolve({
                    tickets: res.data,
                    isLoaded: true
                })
            })
            .catch(err => { return reject(err)})
        })
}

function readParticipationById(id, service){
    return new Promise((resolve, reject) => {
        let url = `participations/${id}`
        service.get(url)
            .then(res => {
                resolve({
                    isLoaded: true,
                    participation: res.data
                })
            })
            .catch(err => { return reject(err)})
        })
}

function readTicketConfirmationById(id, service){
    return new Promise((resolve, reject) => {
        let url = `tickets/uuid/${id}/confirmation`
        service.get(url)
            .then(res => {
                resolve({
                    isLoaded: true,
                    ticket: res.data
                })
            })
            .catch(err => { return reject(err)})
        })
}

function checkInTicket(id, data, service){
    return new Promise((resolve, reject) => {
        let url = `tickets/${id}/checkin`
        service.post(url, data)
            .then(res => {
                resolve({
                    isLoaded: true,
                    ticket: res.data
                })
            })
            .catch(err => { return reject(err)})
        })
}

function readEventData(id, service){
    return new Promise((resolve, reject) => {
        let readWorkshops = `storefront/events/${eventId}/choice-programs`
        let readHosts = `events/${id}/hosts`
        //let readEvent = `events/${id}`
        
        service.getMultiple([readWorkshops, readHosts])
            .then(res => {
                let provisions = res[0].data.provisions;
                let choicePrograms = flattenDeep(res[0].data.eventParts?.map(eP => eP.choicePrograms))
                return resolve({
                    provisions: provisions,
                    workshops: choicePrograms,
                    hosts: res[1].data,
                    isLoaded: true
                })
            })
            .catch(err => { return reject(err)})
    })
}

function updateParticipationById(id, data, service){
    return new Promise((resolve, reject) => {
        service.put('participations/' + id, data)
            .then(res => {
                resolve({
                    isLoaded: true,
                    participation: res.data
                });
            })
            .catch(err => { return reject(err)})
    })
}

export {
    readTicketById,
    readTicketConfirmationById,
    readParticipationById,
    checkInTicket,
    readEventData,
    updateParticipationById,
    searchTicket
}