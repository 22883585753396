import Axios from 'axios';

export class ApiClient {
    api = process.env.REACT_APP_API;
    
    get(url, headerData = null) {
        return Axios.get(`${this.api}/${url}`, headerData)
    }

    getMultiple(urls, headerData = null) {
        let urlList = []
        for(let i = 0; i < urls.length; i++) {
            urlList[i] = this.get(`${urls[i]}`, headerData);
        }
        return Axios.all(urlList).then(Axios.spread((...responses) => {
            return responses;
        }))
    }

    post(url, data, headerData = null) {
        return Axios.post(`${this.api}/${url}`, data, headerData)
    }

    put(url, data, headerData = null) {
        return Axios.put(`${this.api}/${url}`, data, headerData)
    }

    del(url, data, headerData = null) {
        return Axios.delete(`${this.api}/${url}`, data, headerData)
    }

    upload(url, formData, headerData= null) {
        headerData.headers['content-type'] = 'multipart/form-data';
        return this.post(`${url}`, formData, headerData)
    }
}