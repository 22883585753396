import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

const CartSummary = ({products, cart, confirm, cancel, content}) => {
    let view = <></>
    if(products?.length > 0){
        let type = products[0].type;
        let total = 0;
        if(total == 0) products?.forEach(product => total += product.price);
        if(type == "Travel"){
            view = <TravelSummary products={products} total={total} confirm={confirm} cancel={cart != null ? cancel : null} />
        } else if(products[0].fixPrice == false) {
            view = <SummaryWithSelector products={products} total={total} confirm={confirm} cancel={cart != null ? cancel : null} content={content}/>
        } else if(products[0].multiple == true) {
            view = <ProductAndQuantitySummary products={products} total={total} confirm={confirm} cancel={cart != null ? cancel : null} content={content}/>
        } else {
            view = <NormalCartSummary products={products} total={total} confirm={confirm} cancel={cart != null ? cancel : null} />
        }
    }
    return view;
}

const TravelSummary = ({products, total, confirm, cancel}) => {
    products = products?.sort((a, b) => a.eventParts[0] - b.eventParts[0])
    let weeks = products?.map(product => ' ' + product?.name)
    weeks = weeks.toString()

    const [loading, setLoading] = useState(false)
    const preConfirm = () => {
        setLoading(true)
        confirm()
    }
    let className = `button ${loading && "is-loading"}`

    let time = products[0].days?.slice(0, 6) + products[products.length - 1].days?.slice(6)
    return (
        <div>
            <h4>Deine Reisezusammenfassung</h4>
            <h6>Ausgewählte Reisewochen:</h6>
            <p>{weeks}</p>
            <h6>Reisezeitraum:</h6>
            <p>{time}</p>
            <h6>Total:</h6>
            <p>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF'}).format(total)}</p>
            <br />
            {confirm != null && <button className={className} onClick={preConfirm}>Bestätigen</button>}
            {cancel != null && <button className="button secondary" onClick={cancel}>Änderungen verwerfen</button>}
        </div>
    )
}
const NormalCartSummary = ({products, total, confirm, cancel}) => {
    let productsAsText = products?.map(product => product?.name + ', ')?.toString().slice(0, -2)
    const [loading, setLoading] = useState(false)
    const preConfirm = () => {
        setLoading(true)
        confirm()
    }
    let className = `button ${loading && "is-loading"}`
    return (
        <div>
            <h4>Deine Auswahl:</h4>
            <p>{productsAsText}</p>
            <h6>Total:</h6>
            <p>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF'}).format(total)}</p>
            {confirm != null && <button className={className} onClick={preConfirm}>Bestätigen</button>}
            {cancel != null && <button className="button secondary" onClick={cancel}>Änderungen verwerfen</button>}
        </div>
    )
}

// Eigener Selektor
const SummaryWithSelector = ({products, total, confirm, cancel, content}) => {
    let product = products[0]
    let price = products[0]?.price;
    let quantity = product?.quantity ?? 1
    let defaultValue = null;

    const { register, setValue, handleSubmit, formState: { errors }, watch } = useForm()
    const onSubmit = data => {
        data.price = data.price != "" ? parseInt(data.price) : 0;
        data.quantity = data.quantity != "" ? parseInt(data.quantity) : 1
        confirm(data)
    }

    useEffect(() => {
        setValue('price', product?.price)
        setValue('quantity', quantity)
    }, [products]);

    let sum = new Intl.NumberFormat('de-CH', { style: 'currency', currency: "CHF"}).format(parseInt(watch('quantity')) * parseInt(watch('price')))

    console.log(product?.type)

    let view = <p></p>
    if(product != null){
        view = (<>
            <h4>{content['amount' + product?.type]}</h4>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <p>Trage hier deinen effektiven Betrag ein. (Der Richtpreis ist: {product?.price}.00 CHF)</p>
                <br />
                <div className="columns is-mobile">
                    <div className="column is-3-mobile is-3-tablet is-3-desktop is-3-widescreen is-2-fullhd">
                        <h6>CHF</h6>
                    </div>
                    <div className="column">
                        <input type="number" defaultValue={price} {...register('price', { required: true, min: 0})}/>{errors.price && 'Der Preis darf nicht tiefer als 0 sein.'}
                    </div>
                </div>
                {product.multiple && <><div className="columns is-mobile dotted">
                    <div className="column is-3-mobile is-3-tablet is-4-desktop is-4-widescreen is-3-fullhd">
                        <h6>Anzahl</h6>
                    </div>
                    <div className="column ">
                        <input type="number" defaultValue={quantity} {...register('quantity', { required: true, min: 1})}/>{errors.quantity && 'Die Anzahl darf nicht tiefer als 1 sein.'}
                    </div>
                </div>
                <div className="columns is-mobile underlined">
                    <div className="column">
                        <h5>{sum}</h5>
                    </div>
                </div></>}
            <input type="submit" value="Fortfahren" />
            </form>
        </>)
    }
    return view;
}

// Eigener Selektor
const ProductAndQuantitySummary = ({products, total, confirm, cancel, content}) => {
    let product = products[0]
    let quantity = product?.quantity ?? 1
    let defaultValue = null;

    const { register, setValue, handleSubmit, formState: { errors }, watch } = useForm()
    const onSubmit = data => {
        data.price = parseInt(product.price)
        data.quantity = data.quantity != "" ? parseInt(data.quantity) : 1
        confirm(data)
    }

    useEffect(() => {
        setValue('quantity', quantity)
    }, [products]);

    let productValue = new Intl.NumberFormat('de-CH', { style: 'currency', currency: "CHF"}).format(parseInt(product?.price))
    let sum = new Intl.NumberFormat('de-CH', { style: 'currency', currency: "CHF"}).format(parseInt(watch('quantity')) * parseInt(product?.price))

    let view = <p></p>
    if(product != null){
        view = (<>
            <h4>{content['amount' + product?.type]}</h4>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <p>Trage hier deinen effektiven Betrag ein. (Der Richtpreis ist: {product?.price}.00 CHF)</p>
                <br />
                <div className="columns is-mobile">
                    <div className="column is-9-mobile is-8-tablet is-8-desktop is-half-widescreen is-half-fullhd">
                        <h6>{product?.product?.name ?? product?.name}</h6>
                    </div>
                    <div className="column">
                        <h5>{productValue}</h5>
                    </div>
                </div>
                <div className="columns is-mobile dotted">
                    <div className="column is-3-mobile is-3-tablet is-4-desktop is-4-widescreen is-3-fullhd">
                        <h6>Anzahl</h6>
                    </div>
                    <div className="column ">
                        <input type="number" defaultValue={quantity} {...register('quantity', { required: true, min: 1})}/>{errors.quantity && 'Die Anzahl darf nicht tiefer als 1 sein.'}
                    </div>
                </div>
                <div className="columns is-mobile underlined">
                    <div className="column">
                        <h5>{sum}</h5>
                    </div>
                </div>
            <input type="submit" value="Fortfahren" />
            </form>
        </>)
    }
    return view;
}

export default CartSummary;