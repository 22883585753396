import axios from "axios"

const createCart = (cart, service) => {
    return new Promise((resolve, reject) => {
        let products = []
        for(let product of cart?.products){
            if(product.quantity == null){
                products.push({
                    product: product?.id,
                    price: product?.price,
                    currency: 'CHF',
                    quantity: 1
                })
            } else {
                products.push({
                    quantity: product?.quantity,
                    price: product?.price,
                    currency: product?.currency ?? 'CHF',
                    product: product?.product?.id ?? product.id
                })
            }
        }
        let cartData = {
            products: products,
            currency: 'CHF'
          }
          service.post('cart', cartData)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            console.log(err);
            resolve(cart)
          })
    })
}

const updateCart = (session, cart, service) => {
    return new Promise((resolve, reject) => {
        let products = []
        for(let product of cart?.products){
            if(product.quantity == null){
                products.push({
                    product: product?.id,
                    price: product?.price,
                    currency: 'CHF',
                    quantity: 1
                })
            } else {
                products.push({
                    quantity: product?.quantity,
                    price: product?.price,
                    currency: product?.currency ?? 'CHF',
                    product: product?.product?.id ?? product?.id
                })
            }
        }
        let cartData = {
            products: products,
        }
        service.put('cart/session/' + session, cartData)
        .then(res => {
            console.log(res.data);
            resolve(res.data)
        })
        .catch(err => {
            console.log(err);
            resolve(cart)
        })
    })
}

const readCart = (session, service) => {
    return new Promise((resolve, reject) => {
        service.get('cart/session/' + session)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
}

const addCouponToCart = (session, data, service) => {
    return new Promise((resolve, reject) => {
        service.post(`cart/session/${session}/coupons`, data)
        .then(res => {
            resolve(res.data)
        })
        .catch(err => {
            return reject(err)
        })
    })
}

const removeCouponOfCart = (session, code, service) => {
    return new Promise((resolve, reject) => {
        service.delete(`cart/session/${session}/coupons/${code}`)
        .then(res => {
            resolve(res.data)
        })
        .catch(err => {
            return reject(err)
        })
    })
}

export {
    createCart,
    updateCart,
    readCart,
    addCouponToCart,
    removeCouponOfCart
}