import { useForm } from "react-hook-form"
import { Column, Columns } from "../../../layout";

import { useEffect } from "react";

export const AddressForm = ({address, save, cancel, content, message, setSameAddress, sameAddress, register, index}) => {
    const { handleSubmit, watch, formState: { errors }, reset, getValues} = useForm();

    const onSubmit = data => {
        save(data);
    }
  
    useEffect(() => {
        let d = getValues();
        reset({
          ...d,
          ...address
        })
    }, [address])
  
    return (
        <div>
            {message && <div className="notification is-warning is-light">
                {message}
            </div>}
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                {sameAddress && 
                <>
                    <Columns>
                        <Column>
                            <label className="label">{content.sameAddress}</label>
                            <select className="input" name="sameAddress" defaultValue={false} onChange={(ev) => setSameAddress(ev.target.value)}>
                            <option value={false}>{content.no}</option>
                            <option value={true}>{content.yes}</option>
                            </select>
                        </Column>
                    </Columns>
                </>
                }
                <Columns>
                    <Column>
                        <label className="label">{content.addressLine1}</label>
                        <input className="input" type="text" name="addressLine1" defaultValue={address.addressLine1} {...register(`addresses[${index}].addressLine1`,{ required: true })} />
                        {errors.addressLine1 && <span>{content.fieldRequired}</span>}
                    </Column>
                </Columns>
                <Columns>
                    <Column>
                        <label className="label">{content.addressLine2}</label>
                        <input className="input" type="text" name="addressLine2" defaultValue={address.addressLine2} {...register(`addresses[${index}].addressLine2`)} />
                    </Column>
                </Columns>
                <Columns>
                    <Column classes="is-one-third">
                        <label className="label">{content.plz}</label>
                        <input className="input" type="text" name="plz" defaultValue={address.plz} {...register(`addresses[${index}].plz`,{ required: true })} />
                        {errors.plz && <span>{content.fieldRequired}</span>}
                    </Column>
                    <Column>
                        <label className="label">{content.city}</label>
                        <input className="input" type="text" name="city" defaultValue={address.city} {...register(`addresses[${index}].city`,{ required: true })} />
                        {errors.city && <span>{content.fieldRequired}</span>}
                    </Column>
                </Columns>
                <Columns>
                    <Column>
                        <label className="label">{content.country}</label>
                        <select className="input" name="country" defaultValue={address.country} {...register(`addresses[${index}].country`,{ required: true })}>
                            <option value="CH">{content.switzerland}</option>
                            <option value="DE">{content.germany}</option>
                            <option value="FR">{content.france}</option>
                            <option value="IT">{content.italy}</option>
                            <option value="LI">{content.liechtenstein}</option>
                            <option value="AT">{content.austria}</option>
                        </select>
                        {errors.country && <span>{content.fieldRequired}</span>}
                    </Column>
                </Columns>
                <Columns>
                    {cancel && <Column>
                        <button className="button is-pulled-left" onClick={cancel}>{content.cancel ?? "Keine Adresse erstellen"}</button>
                    </Column> }
                </Columns>
            </form>
        </div>
    );
}