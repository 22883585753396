import { useEffect, useState } from "react"
import Product from "./Product"
import ProductsFilter from "./ProductsFilter"

const ProductsList = ({products, toggleProduct}) => {
    const action = data => {
        toggleProduct(data)
    }
    const [showProducts, setShowProducts] = useState([])
    const [filterable, setFilterable] = useState(false);
    const [activeFilter, setActiveFilter] = useState(null);
    const [filters, setFilters] = useState([])

    const doFilter = filter => {
        setActiveFilter(filter);
        setShowProducts(products.filter(product => product.filter == filter))
    }

    useEffect(() => {
        if(window.productFilter) {
            let f = window.productFilter
            setFilters(f);
            doFilter(activeFilter ?? f[0].value)
            setFilterable(true)
        } else {
            setShowProducts(products)
        }
    }, [products, toggleProduct])

    return (
        <>
            {filterable && <div className="columns"><div className="column"><ProductsFilter activeFilter={activeFilter} setFilter={doFilter} filters={filters} /></div></div>}
            <div className="columns is-mobile is-multiline">
                {showProducts.map(product => {
                    return (
                        <>
                            <Product product={product} addProduct={action} key={product?.id}/>
                            <br />
                        </>
                    )
                })}
            </div>
        </>
    )
}

export default ProductsList