import axios from "axios";
import _ from "lodash";
import { parse, addHours } from 'date-fns';
import { ErrorFormatter } from "../utils/ErrorFormatter";

const createContact = (data, lang, service) => {
    let birthday = parse(data.birthday, 'dd.MM.yyyy', new Date());
    birthday = addHours(birthday, 6);

    let addresses = []
    if(data.addresses != null){
      addresses = data.addresses;
    } else {
      addresses = [{
        type: 'Bill',
        primary: true,
        address: {
          addressLine1: _.upperFirst(data.addressLine1),
          addressLine2: data.addressLine2,
          plz: data.plz,
          city: _.upperFirst(data.city),
          country: data.country
        }
      }]
    }

    return new Promise((resolve, reject) => {
        let personData = {
          type: 'Person',
          salutation: data.salutation,
          title: _.upperFirst(data.title),
          firstName: _.upperFirst(data.firstName),
          lastName: data.lastName,
          email: _.toLower(data.email),
          mobile: data.mobile,
          birthday: birthday,
          addresses: addresses,
          marketing: {
            addingReason: 'Teilnahme',
            type: 'Teilnehmer',
            letterActive: true,
            letterDeactivationReason: null,
            emailActive: true,
          },
          language: _.toUpper(lang)
        }
        service.post(`contacts`, personData)
        .then(res => {
          return resolve(res.data)
        })
        .catch(err => {
          return reject(err)
        })
      })
}

const updateContact = (id, data, service) => {
    return new Promise((resolve, reject) => {
      service.put(`contacts/${id}`, data)
        .then(res => {
            resolve(res.data)
        })
        .catch(err => {
          console.log(err)
        })
    })
}

const readContact = (id, service) => {
    return new Promise((resolve, reject) => {
      service.get('contacts/' + id)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
}

const addAddressToContact = (id, data, service) => {
  return new Promise((resolve, reject) => {
    service.post(`contacts/${id}/addresses`, data)
      .then(res => {
          resolve(res.data)
      })
      .catch(err => {
        console.log(err)
      })
  })
}

function findContacts(searchTerm, service){
  return new Promise((resolve, reject) => {
      service.get(`contacts/find?searchTerm=${searchTerm}`)
          .then(res => {
              resolve({
                  contacts: res.data
              })
          })
          .catch(err => { return reject(ErrorFormatter(err))})
  })
}

export {
    createContact,
    readContact,
    updateContact,
    addAddressToContact,
    findContacts
}