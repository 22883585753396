import { formatInput } from "../utils/FormatInput";

const EditParticipationInfoBox = props => {
    let { fields, data } = props;
    if(data == null){
        data = {};
        let args = [];
        for(let field of fields){
            data[field.name] = "";
            if(field.type == "select"){
                data[field.name] = field.options[0];
            }
            args.push(field.name)
        }
        data.args = args;
        data.title = fields[0].box;
    }

    const handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        props.setChange({key: name, value: value});
    }

    return (
        <>
            {fields.map(field => {
                let input;
                let value = "";
                if(data != null){
                    if(data[field.name] != null) {
                        value = data[field.name]
                    } else {
                        let info = data?.find(info => info.key == field.name)
                        if(info != null) {
                            value = info.value
                        }
                    }
                }
                switch(field.type){
                    case "select":
                        let defaultValue = value ?? "false";
                        input = <select onChange={handleInputChange} name={field.name} value={defaultValue} className="input" >{field.options.map(option => { return <option value={option.value} selected={option == value}>{formatInput(option.label)}</option>})}</select>
                        break;
                    case "text":
                        input = <input onChange={handleInputChange} className="input" type="text" name={field.name} value={value} />
                        break;
                    default:
                        input = <input onChange={handleInputChange} className="input" type="text" name={field.name} value={value} />
                        break;
                }
                return (
                    <>
                        <h6>{field.label}</h6>
                        {input}
                    </>
                )
            })}
        </>
    )
}

export default EditParticipationInfoBox;